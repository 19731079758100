import {
    IABTest,
    IABTestService,
    TestFlag,
    IEventPayload,
} from '../../../types/abtests'

export default class ABTest<
    Variables extends string,
    Events extends string,
    FlagType = TestFlag
> implements IABTest<Variables, Events>
{
    private readonly service: IABTestService<FlagType>
    private readonly flag: FlagType
    private readonly userId?: string

    private cachedVariation?: string | null

    constructor(
        abtests: IABTestService<FlagType>,
        flag: FlagType,
        userId?: string
    ) {
        this.service = abtests
        this.flag = flag
        this.userId = userId
    }

    get enabled() {
        return this.service.isEnabled(this.flag, this.userId)
    }

    get variation() {
        if (this.cachedVariation) {
            return this.cachedVariation
        }
        this.cachedVariation = this.service.getVariation(this.flag, this.userId)
        return this.cachedVariation
    }

    get initialized() {
        return this.service.initialized
    }

    getEnabled(payload?: Record<string, any>) {
        return this.service.isEnabled(this.flag, this.userId, payload)
    }

    getValue(
        variable?: Variables,
        payload?: Record<string, any>
    ): number | string | boolean | undefined | null {
        const value = this.service.getValue(
            this.flag,
            variable,
            this.userId,
            payload
        )
        return value
    }

    getVariation(payload: Record<string, any>) {
        return this.service.getVariation(this.flag, this.userId, payload)
    }

    track(event: Events, payload?: IEventPayload): void {
        return this.service.track(event, this.userId, payload)
    }
}
