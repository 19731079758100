import CreditsModal from '../shared/CreditsModal'

type Props = {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
}

export default function CalendarCreditsModal({
    isOpen,
    onClose,
    onConfirm,
}: Props) {
    return (
        <CreditsModal
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={onConfirm}
            actionText="Earn $100 credit"
        >
            We’re confident you’ll have more collaborative and focused meetings
            in Teamflow. Transfer your existing recurring meetings over to
            Teamflow and we’ll credit your account with $100.
        </CreditsModal>
    )
}
