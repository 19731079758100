import { useCallback, useEffect, useRef } from 'react'

// initially copied from https://stackoverflow.com/a/70716215/710693

/**
 * This hook provides a function that returns whether the component is still mounted.
 * This is useful as a check before calling set state operations which will generates
 * a warning when it is called when the component is unmounted.
 * @returns a function
 */
export function useMounted(): () => boolean {
    const mountedRef = useRef(false)
    useEffect(function useMountedEffect() {
        mountedRef.current = true
        return function useMountedEffectCleanup() {
            mountedRef.current = false
        }
    }, [])
    return useCallback(
        function isMounted() {
            return mountedRef.current
        },
        [mountedRef]
    )
}
