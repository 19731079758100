import { observer } from 'mobx-react'
import {
    MouseEventHandler,
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { rrulestr } from 'rrule'

import { api } from '@teamflow/client-api'
import {
    Box,
    Button,
    Card,
    Center,
    Checkbox,
    Link,
    Skeleton,
    Stack,
    Text,
} from '@teamflow/design'
import { getPublicUrl } from '@teamflow/lib'
import rootStore, { LocalUser } from '@teamflow/store'
import allTKeys from '@teamflow/translations'
import { Experiment, RecurringEvent } from '@teamflow/types'

import { MEETING_MIGRATION_LOCAL_STORAGE_KEY } from '../../constants'
import { analytics, google } from '../../helpers'
import { useOptimizelyTest } from '../../hooks/useABTest'

import HuddleHeading from '../common/HuddleHeading'
import CalendarCreditsModal from '../experiments/calendarCredits/CalendarCreditsModal'

const translationPage = 'signup'
const tKeys = allTKeys.signup.meetingMigration

type MeetingMigrationProps = {
    onContinue: () => void
    onboardingMeetings: {
        events?: Record<string, RecurringEvent[]>
        duplicates?: Record<
            string,
            {
                eventId: string
                calendarId: string
            }[]
        >
        loading: boolean
        error?: boolean
        mutate(): void
    }
    userOrg: LocalUser | null
    isMeeting: boolean
}

function serializeEventsForMigration(
    state: Record<string, RecurringEvent[]>,
    duplicates: Record<string, { eventId: string; calendarId: string }[]> = {}
) {
    const events: Array<{
        eventId: string
        calendarId: string
    }> = []

    for (const category in state) {
        const categoryEvents = state[category]

        for (const event of categoryEvents) {
            if (!event.checked) {
                continue
            }

            const allEvents = duplicates[event.summary]
                ? duplicates[event.summary]
                : [{ eventId: event.id, calendarId: event.calendarId }]

            for (const { eventId, calendarId } of allEvents) {
                events.push({ eventId, calendarId })
            }
        }
    }

    return events
}

export default observer(function MeetingMigration({
    onContinue,
    onboardingMeetings,
    userOrg,
    isMeeting,
}: MeetingMigrationProps) {
    const {
        loading,
        error,
        events: onboardingEvents,
        duplicates,
        mutate,
    } = onboardingMeetings
    const user = rootStore.users.localUser
    const { t } = useTranslation(translationPage)
    const [count, setCount] = useState(0)
    const [checkedCount, setCheckedCount] = useState(0)
    const toggleChecked = useCallback((ev: RecurringEvent) => {
        ev.checked = !ev.checked
        setCheckedCount((count) => count + (ev.checked ? 1 : -1))
    }, [])
    const [didTimeout, setDidTimeout] = useState(false)
    const { test: meetingMigration } = useOptimizelyTest(
        Experiment.MeetingMigration,
        user?._id,
        {
            attributes: { role: user?.role ?? 0, googleSSO: user?.googleSSO },
        }
    )
    const preferredCalendar = rootStore.users.globalUser?.preferredCalendar

    useEffect(() => {
        if (!userOrg?.googleSSO) {
            return onContinue()
        }
        if (loading) {
            return
        }
        if (onboardingEvents) {
            const count = Object.keys(onboardingEvents).reduce(
                (count, key) => count + onboardingEvents[key].length,
                0
            )
            setCount(count)
            setCheckedCount(count)
        } else if (!error) {
            // Continue if no events
            analytics.track(analytics.SIGNUP_MEETING_MIGRATION, {
                status: 'no_meetings',
            })
            onContinue()
        }
    }, [
        loading,
        userOrg?.googleSSO,
        mutate,
        onboardingEvents,
        error,
        onContinue,
    ])

    useEffect(() => {
        // Allow user to skip if loading takes longer than 5s.
        setTimeout(() => setDidTimeout(true), 5000)
    }, [])

    const onSkip = useCallback(() => {
        analytics.track(analytics.SIGNUP_MEETING_MIGRATION, {
            status: 'skip',
        })
        onContinue()
    }, [onContinue])

    const onSubmit = useCallback(
        async (incentivizedKey?: string) => {
            if (!onboardingEvents || !preferredCalendar) return

            let eventsTotal = 0
            const eventCounts: Record<string, number> = Object.keys(
                onboardingEvents
            ).reduce((record, key) => {
                record[key] = onboardingEvents[key].length
                eventsTotal += onboardingEvents[key].length
                return record
            }, {} as Record<string, number>)
            eventCounts.total = eventsTotal

            analytics.track(analytics.SIGNUP_MEETING_MIGRATION, {
                status: 'success',
                events: eventCounts,
            })

            meetingMigration.track('meeting-migrated', {
                count: eventCounts.total,
            })

            const serialized = serializeEventsForMigration(
                onboardingEvents,
                duplicates
            )

            if (preferredCalendar === 'outlook') {
                await api.user.calendar.outlookRecurringEventsConvert({
                    events: serialized,
                    incentivizedKey,
                    isMeeting,
                })
            } else {
                await api.user.calendar.conversion.execute({
                    events: serialized,
                    incentivizedKey,
                    isMeeting,
                })
            }

            window.localStorage.setItem(
                MEETING_MIGRATION_LOCAL_STORAGE_KEY,
                'true'
            )
            onContinue()
        },
        [
            duplicates,
            isMeeting,
            meetingMigration,
            onContinue,
            onboardingEvents,
            preferredCalendar,
        ]
    )

    const onAuthorize = useCallback(() => {
        void google.requestPermissions('calendar').then(mutate)
    }, [mutate])

    return (
        <Box width="fill" display="flex" flexDirection="column">
            <HuddleHeading>
                {loading
                    ? t(tKeys.findingYourRecurringMeetings)
                    : error
                    ? 'Sync your Google Calendar with Teamflow'
                    : t(tKeys.weFoundCountRecurringMeetings, { count })}
            </HuddleHeading>
            <Center>
                <Text color="neutral80" size="textSize14">
                    {t(tKeys.moveThemToTeamflowToHaveBetterMoreFocusedMeetings)}
                </Text>
            </Center>
            {(loading || !error) && (
                <>
                    <CalendarBox>
                        {onboardingEvents &&
                            Object.keys(onboardingEvents).map((key, i) =>
                                onboardingEvents[key].length ? (
                                    <MeetingCategoryCard
                                        key={key}
                                        events={onboardingEvents[key]}
                                        loading={loading}
                                        skeletonCards={i % 2 === 0 ? 2 : 3}
                                        title={key}
                                        toggleChecked={toggleChecked}
                                    />
                                ) : null
                            )}
                    </CalendarBox>
                    <MigrateActionBar
                        checkedCount={checkedCount}
                        isOpen={!loading || didTimeout}
                        onContinue={onSkip}
                        onSubmit={onSubmit}
                        userOrg={userOrg}
                    />
                </>
            )}
            {!loading && error && (
                <>
                    <Button
                        size="lg"
                        isFullWidth
                        marginTop="space32"
                        onClick={onAuthorize}
                    >
                        Authorize Google Calendar
                    </Button>
                    <Box marginTop="space16">
                        <Text align="center" width="fill">
                            <Link level="neutral" onClick={onContinue}>
                                No thanks, skip this step
                            </Link>
                        </Text>
                    </Box>
                </>
            )}
        </Box>
    )
})

const meetingCategoryCardImageStyle = {
    margin: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    borderRadius: 6,
} as const

function MeetingCategoryCard({
    title,
    events,
    loading,
    skeletonCards,
    toggleChecked,
}: {
    title: string
    events: RecurringEvent[]
    loading: boolean
    skeletonCards: number
    toggleChecked: (ev: RecurringEvent, value: boolean) => void
}) {
    const { t } = useTranslation(translationPage)

    const CATEGORY_DECORATIONS: Record<
        string,
        {
            summary: string
            image: string
            gif: string
        }
    > = useMemo(
        () => ({
            Standups: {
                summary: t(
                    tKeys.formACircleWithYourTeamBringYourFavoritePlanningToolNextToYouAndGo
                ),
                image: getPublicUrl('/images/signup/standup.png'),
                gif: getPublicUrl('/images/signup/standup.gif'),
            },
            Retrospectives: {
                summary: t(
                    tKeys.setUpATimerAndReviewWhatWorkedAndDidntWorkWithYourSprintTeam
                ),
                image: getPublicUrl('/images/signup/retro.png'),
                gif: getPublicUrl('/images/signup/retro.gif'),
            },
            "Office Hours & 1:1's": {
                summary: t(
                    tKeys.haveMorePersonalAndNaturalOfficeHoursAnd11MeetingsWithYourTeam
                ),
                image: getPublicUrl('/images/signup/office-hours.png'),
                gif: getPublicUrl('/images/signup/office-hours.gif'),
            },
            'Happy Hours & Team Lunches': {
                summary: t(
                    tKeys.useSpatialAudioAndPlayGamesTogetherLikeSkribblPokerOrTetris
                ),
                image: getPublicUrl('/images/signup/lunch.png'),
                gif: getPublicUrl('/images/signup/lunch.gif'),
            },
            'Other meetings': {
                summary: t(
                    tKeys.useSpatialAudioAndPlayGamesTogetherLikeSkribblPokerOrTetris
                ),
                image: getPublicUrl('/images/signup/lunch.png'),
                gif: getPublicUrl('/images/signup/lunch.gif'),
            },
        }),
        [t]
    )

    const { summary, image, gif } = CATEGORY_DECORATIONS[title] ?? {}

    let content: JSX.Element

    if (loading) {
        content = (
            <Stack space="space12">
                {Array(skeletonCards)
                    .fill(0)
                    .map((_, i) => (
                        <MeetingEventCardSkeleton key={i} />
                    ))}
            </Stack>
        )
    } else if (events.length > 0) {
        content = (
            <Stack space="space12">
                {events.map((ev) => (
                    <MeetingEventCard
                        key={ev.id}
                        event={ev}
                        toggleChecked={toggleChecked}
                    />
                ))}
            </Stack>
        )
    } else {
        content = <MeetingCategoryEmptySticker title={title} />
    }

    const [isHovered, setIsHovered] = useState(false)

    const handleMouseEnter = useCallback(() => {
        setIsHovered(true)
    }, [])

    const handleMouseLeave = useCallback(() => {
        setIsHovered(false)
    }, [])

    return (
        <Card
            flexShrink={0}
            display="flex"
            flexDirection="column"
            gap="space12"
            background="background"
            shadow="shadow4"
            border="neutral40"
            padding="space16"
            radius="borderRadius8"
            __cssOverrides={{
                width: 300,
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Box width="fill" __cssOverrides={{ height: 240 }}>
                <img
                    src={isHovered ? getPublicUrl(gif) : getPublicUrl(image)}
                    alt={t(tKeys.liveTitleAnimation, { title })}
                    style={meetingCategoryCardImageStyle}
                />
            </Box>
            <Text weight="600">{title}</Text>
            {summary && (
                <Text size="textSize14" color="neutral70">
                    {summary}
                </Text>
            )}
            {content}
        </Card>
    )
}

function MeetingCategoryEmptySticker({ title }: { title: string }) {
    const { t } = useTranslation(translationPage)

    const keyword = title.toLowerCase()

    return (
        <Center
            borderRadius="borderRadius8"
            height="size64"
            padding="space16"
            borderColor="neutral50"
            borderWidth="borderWidth2"
            borderStyle="dashed"
        >
            <Text color="neutral60" size="textSize14">
                {t(tKeys.noKeywordToAdd, { keyword })}
            </Text>
        </Center>
    )
}

function MeetingEventCard({
    event,
    toggleChecked,
}: {
    event: RecurringEvent
    toggleChecked: (ev: RecurringEvent, value: boolean) => void
}) {
    const [checked, setCheckedState] = useState<boolean>(event.checked)
    const setChecked = useCallback(
        (value: boolean) => {
            toggleChecked(event, value)
            setCheckedState(value)
        },
        [event, toggleChecked]
    )
    const recurrenceText = useRef(
        (() => {
            const rule = rrulestr(event.recurrence.join('\n'))

            // rule.options.until = null

            const text = rule.toText()

            return text.substring(0, 1).toUpperCase() + text.substring(1)
        })()
    )

    const handleWrapperClick = useCallback(() => {
        setChecked(!checked)
    }, [checked, setChecked])

    const handleCheckboxWrapperClick: MouseEventHandler = useCallback((e) => {
        // stop event from propagating to MeetingEventCardWrapper
        e.stopPropagation()
    }, [])
    return (
        <MeetingEventCardWrapper onClick={handleWrapperClick}>
            <Box display="flex" alignItems="center" gap="space12" width="fill">
                <Box onClick={handleCheckboxWrapperClick}>
                    <Checkbox checked={checked} onChange={setChecked} />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap="space4"
                    overflow="hidden"
                >
                    <Text
                        size="textSize14"
                        truncate
                        selectable={false}
                        __cssOverrides={{ lineHeight: 1.5 }}
                    >
                        {event.summary}
                    </Text>
                    <Text
                        size="textSize12"
                        color="neutral70"
                        truncate
                        selectable={false}
                        __cssOverrides={{ lineHeight: 1.5 }}
                    >
                        {recurrenceText.current}
                    </Text>
                </Box>
            </Box>
        </MeetingEventCardWrapper>
    )
}

function MeetingEventCardSkeleton() {
    return (
        <MeetingEventCardWrapper>
            <Stack space="space8">
                <Skeleton height="size20" __cssOverrides={{ width: '100%' }} />
                <Skeleton height="size20" __cssOverrides={{ width: '80%' }} />
            </Stack>
        </MeetingEventCardWrapper>
    )
}

// The individual meetings
function MeetingEventCardWrapper({
    children,
    onClick,
}: PropsWithChildren<{
    onClick?: () => void
}>) {
    return (
        <Card
            background="neutral20"
            border="neutral40"
            shadow="shadow2"
            radius="borderRadius8"
            padding="space16"
            cursor="pointer"
            onClick={onClick}
        >
            {children}
        </Card>
    )
}

function CalendarBox({ children }: PropsWithChildren<{}>) {
    return (
        <Box
            alignSelf="center"
            display="flex"
            alignItems="start"
            justifyContent="center"
            gap="space24"
            flexWrap="wrap"
            marginTop="space32"
            paddingX="space32"
            __cssOverrides={{
                width: '100vw',
                // 128 is height of MigrateActionBar
                marginBottom: 32 + 128,
                // limit to three cards in one row
                maxWidth: 1100,
            }}
        >
            {children}
        </Box>
    )
}

enum CreditState {
    Inactive,
    Modal,
}

const MigrateActionBar = observer(function MigrateActionBar({
    checkedCount,
    isOpen = true,
    onContinue,
    onSubmit,
    userOrg,
}: {
    checkedCount: number
    isOpen?: boolean
    onContinue: () => void
    onSubmit: (incentivizedKey?: string) => void
    userOrg: LocalUser | null
}) {
    const { t } = useTranslation(translationPage)
    const { active: creditTestActive } = useOptimizelyTest(
        Experiment.CalendarCredit,
        userOrg?._id,
        {
            attributes: { role: userOrg?.role ?? 0 },
        }
    )
    const [creditState, setCreditState] = useState(CreditState.Inactive)

    const onSkip = useCallback(() => {
        if (creditTestActive) {
            setCreditState(CreditState.Modal)
            analytics.track(analytics.Signup.CalendarCreditShow)
        } else {
            onContinue()
        }
    }, [creditTestActive, onContinue])

    const handleSubmit = useCallback(() => {
        onSubmit()
    }, [onSubmit])

    const handleCreditsModalConfirm = useCallback(() => {
        onSubmit(Experiment.CalendarCredit)
        analytics.track(analytics.Signup.CalendarCreditAccept)
    }, [onSubmit])

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            background="neutral20"
            left="none"
            right="none"
            position="fixed"
            __cssOverrides={{
                boxShadow: '0px -12px 20px rgba(0, 0, 0, 0.12)',
                bottom: isOpen ? 0 : -128,
                height: 128,
                transition: 'bottom 200ms',
            }}
        >
            <Button
                size="lg"
                isFullWidth
                onClick={handleSubmit}
                __cssOverrides={{ width: 448 }}
            >
                {checkedCount
                    ? t(tKeys.transferCheckedCountMeetingsToTeamflow, {
                          checkedCount,
                      })
                    : t(tKeys.dontTransferMeetingsToTeamflow)}
            </Button>
            <Box marginTop="space16">
                <Text align="center" width="fill">
                    <Link level="neutral" onClick={onSkip}>
                        {t(tKeys.noThanksIllDoThisLater)}
                    </Link>
                </Text>
            </Box>
            <CalendarCreditsModal
                isOpen={creditState === CreditState.Modal}
                onClose={onContinue}
                onConfirm={handleCreditsModalConfirm}
            />
        </Box>
    )
})
