import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react'
import { TFunction } from 'next-i18next'
import Router from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    formatPhoneNumberIntl,
    isPossiblePhoneNumber,
} from 'react-phone-number-input'

import { useFeatureFlag } from '@teamflow/bootstrap'
import { api } from '@teamflow/client-api'
import { Button, Input, ItemWithMessage, Select, Stack } from '@teamflow/design'
import { ID_REFRESH_TOKEN_NAME, LogManager } from '@teamflow/lib'
import rootStore, { GlobalUser } from '@teamflow/store'
import allTKeys from '@teamflow/translations'
import {
    CreateOrgReqBody,
    CreateOrgResBody,
    Experiment,
    Feature,
    FlowType,
    OrgSize,
    Product,
} from '@teamflow/types'
import {
    eventWithFlow,
    Flow as AnalyticsFlow,
    identify,
    Signup,
    track,
} from '@teamflow/web/src/helpers/analytics'

import { ID_TOKEN_NAME, MAX_ORG_NAME_LEN } from '../../constants'
import { useOptimizelyTest } from '../../hooks/useABTest'
import useEnableScroll from '../../hooks/useEnableScroll'
import { useMounted } from '../../hooks/useMounted'

import HuddleHeading from '../common/HuddleHeading'
import HuddleLoader from '../common/HuddleLoader'
import { PhoneNumberInput } from '../common/PhoneNumberInput'

import type { FormEvent, MouseEvent } from 'react'

const translationPage = 'signup'
const tKeys = allTKeys.signup.company

type Props = {
    user: GlobalUser | null
    inviteCode?: string
    setCompany: (name: string) => void
    // only used by flo and admins to create a company on behalf of user
    setAdminOrg?: (org: any) => void
    useCC?: boolean
    flowType?: FlowType
    referrer?: string
    source: AnalyticsFlow
    onContinue?: () => void
}

export async function createCompany(
    t: TFunction,
    body: CreateOrgReqBody
): Promise<{
    res?: CreateOrgResBody
    nameTaken?: boolean
    errorMessage?: string
}> {
    const { data, error } = await api.organization.create(body)

    if (error) {
        const { message = 'Server error - no message' } = error
        if (
            message.includes('duplicate key') ||
            message.includes('already taken')
        ) {
            return {
                nameTaken: true,
                errorMessage: t(tKeys.companyNameAlreadyExists),
            }
        }

        if (message.includes('is required')) {
            return {
                errorMessage: t(tKeys.companyNameRequired),
            }
        }
        Sentry.captureException(error, {
            tags: {
                is_signup: true,
                page: 'company',
            },
        })
    }

    if (!data) {
        return {
            errorMessage: t(tKeys.genericCompanyError),
        }
    }

    return { res: data }
}

export async function handleCompanyCreated(
    res: CreateOrgResBody,
    utmParams: Record<string, string>,
    setCompany: (name: string) => void,
    useCC: boolean,
    source: AnalyticsFlow
) {
    const { user, userOrg, org } = res

    if (!org || !user || !userOrg) {
        LogManager.ui.error(`handleCompanyCreated missing org, user or userOrg`)
        return
    }

    rootStore.users.updateGlobalUser(user)
    rootStore.users.updateLocalUser(userOrg)
    setCompany(org.name)

    identify(userOrg._id, {
        userId: userOrg._id,
        joinTeamLink: `${process.env.NEXT_PUBLIC_APP_URL}/join/${org.slug}/${org.inviteCode}`,
        linkToInvitePage: `${process.env.NEXT_PUBLIC_APP_URL}/signup?inviteCode=${org.inviteCode}`,
        ...utmParams,
    })

    window.dataLayer?.push({
        event: 'formsubmission',
        conversion: true,
        method: userOrg.auth,
    })

    track(eventWithFlow(Signup.CreatedNewOrg, source), {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        user_org_id: user._id,
        org_id: org._id,
        company: org.displayName,
    })

    if (utmParams && Object.keys(utmParams).length) {
        // upload to DB
        const res = await api.marketing.create({
            utmParams,
            email: user.email,
            name: `${user.firstName} ${user.lastName}`,
            company: org.name,
        })
        if (res.error) {
            Sentry.captureException(res.error, {
                tags: { is_signup: true, page: 'company' },
            })
        }
    }

    // remove from storage
    rootStore.marketing.setUtmParameters({})

    if (!useCC) {
        await Router.push({
            pathname:
                Router.pathname.indexOf('sign-up') >= 0
                    ? '/sign-up'
                    : '/signup',
            query: {
                ...Router.query,
                inviteCode: org.inviteCode,
                conversion: true,
            },
        })
    }
}

export default observer(function SignupCompany({
    user,
    setCompany,
    setAdminOrg,
    useCC = false,
    flowType = FlowType.Normal,
    referrer = '',
    source,
    onContinue,
}: Props): JSX.Element {
    const { t } = useTranslation(translationPage)

    const [errorMsg, setErrorMsg] = useState('')
    const [nameTaken, setNameTaken] = useState(false)
    const [companyTyped, setCompanyTyped] = useState(false)
    const [loadingOrg, setLoadingOrg] = useState(false)
    const [form, setFormValue] = useState({
        name: '',
        size: '',
        role: '',
        phone: '',
        hubspot_cookie: '',
    })

    const [isPhoneInvalid, setIsPhoneInvalid] = useState(false)
    const [isCompanySizeInvalid, setIsCompanySizeInvalid] = useState(false)
    const [isCompanyNameInvalid, setIsCompanyNameInvalid] = useState(false)
    const [hubspotCookie, setHubspotCookie] = useState('')

    const { enabled: requestPhoneNumber } = useFeatureFlag({
        flag: Feature.RequestPhoneNumber,
    })

    const loading = rootStore.users.localUserLoading

    const utmParams = { ...rootStore.marketing.utm }
    const hideRole = useFeatureFlag(Feature.SignupHideRole)
    const { test: signupPhoneTest, active: signupPhoneActive } =
        useOptimizelyTest(Experiment.SignupPhone, user?._id)

    const showPhoneNumber =
        (requestPhoneNumber && user?.auth === 'googleSSO') || signupPhoneActive
    const isMounted = useMounted()

    useEnableScroll()

    useEffect(() => {
        track(eventWithFlow(Signup.SetUpNewOrgShown, source))
    }, [source])

    useEffect(() => {
        const cookies = document.cookie.split(';')

        if (cookies.length > 0) {
            const hs_cookie = cookies.find((cookie) =>
                cookie.includes('hubspotutk')
            )
            if (hs_cookie) {
                const [, hubspot_cookie] = hs_cookie.split('=')
                setHubspotCookie(hubspot_cookie)
            }
        }
    }, [])

    const setInitialName = useCallback(async () => {
        if (!user?.email || companyTyped) return
        const emailDomainMatch = user.email.match(/@(\w+)/)
        if (emailDomainMatch && emailDomainMatch.length > 1) {
            const emailDomain = emailDomainMatch[1]
            let incremental = 0
            let orgName = emailDomain
            while (
                (await api.organization.exists({ orgName }))?.data?.exists &&
                incremental < 5 // only try to find a name 5 times to prevent and infinite loop here
            ) {
                orgName = `${emailDomain}-${++incremental}`
            }
            if (isMounted() && !companyTyped) {
                // capitalize first letter
                orgName = orgName[0].toUpperCase() + orgName.slice(1)
                setFormValue((prevFormValue) => ({
                    ...prevFormValue,
                    name: prevFormValue.name ? prevFormValue.name : orgName,
                }))
            }
        }
    }, [companyTyped, isMounted, user?.email])

    useEffect(() => {
        const cookies = document.cookie.split(';')

        if (cookies.length > 0) {
            const hs_cookie = cookies.find((cookie) =>
                cookie.includes('hubspotutk')
            )
            if (hs_cookie) {
                const [, hubspot_cookie] = hs_cookie.split('=')
                setHubspotCookie(hubspot_cookie)
            }
        }

        void setInitialName()
    }, [setInitialName])

    async function onSubmit(
        e: FormEvent<HTMLFormElement> | MouseEvent<Element>
    ) {
        e.preventDefault()

        const { name, size, role, phone } = form

        if (user?.currentOrgId) {
            await api.user.exitOrganization()
        }

        setIsCompanyNameInvalid(false)
        setIsPhoneInvalid(false)
        setIsCompanySizeInvalid(false)
        if (!name) {
            setIsCompanyNameInvalid(true)
            setErrorMsg(t(tKeys.aCompanyNameIsRequired))
            return
        }

        if (showPhoneNumber && !isPossiblePhoneNumber(phone)) {
            setErrorMsg(t(tKeys.pleaseEnterAValidPhoneNumber))
            setIsPhoneInvalid(true)
            return
        }

        if (!size) {
            setErrorMsg(t(tKeys.pleaseSelectCompanySize))
            setIsCompanySizeInvalid(true)
            return
        }

        const checkAdmin = !!setAdminOrg
        const body: CreateOrgReqBody = {
            name,
            size,
            jobTitle: role,
            flow: flowType,
            referrer,
            phone: formatPhoneNumberIntl(phone),
            // NOTE: this will just let the API know whether it should
            // go through the special admin flow (if you are admin)
            // otherwise admins cannot create new workspaces ;)
            checkAdmin,
            hubspotCookie,
            utm: utmParams,
        }

        if (flowType === FlowType.CreateSalesfloor) {
            body.product = Product.SalesFloor
        }

        if (checkAdmin) {
            LogManager.ui.warn(
                'Trying to create org as an admin. Hope you know what you are doing!'
            )
        }
        setLoadingOrg(true)
        const { errorMessage, nameTaken, res } = await createCompany(t, body)
        setLoadingOrg(false)

        if (errorMessage || !res || !res.org || !res.user || !res.userOrg) {
            setNameTaken(!!nameTaken)
            setErrorMsg(errorMessage ?? 'Something went wrong')

            track(Signup.Error, {
                type: 'org',
                errorMessage,
            })
        } else {
            signupPhoneTest.track('org-created')

            const { idToken, refreshToken, org, guestInviteCode } = res
            if (idToken) {
                window.localStorage.setItem(ID_TOKEN_NAME, idToken)
            }
            if (refreshToken) {
                window.localStorage.setItem(ID_REFRESH_TOKEN_NAME, idToken)
            }

            if (setAdminOrg) {
                const orgToSet = { ...org, guestInviteCode }
                setAdminOrg(orgToSet)
                return
            }

            await handleCompanyCreated(
                res,
                utmParams,
                setCompany,
                useCC,
                source
            )

            onContinue?.()
        }
    }

    const handlePhoneNumberChange = useCallback(
        (value: string) => {
            setIsPhoneInvalid(false)
            setFormValue({ ...form, phone: value })
        },
        [form]
    )

    const handleCompanySizeChange = useCallback(
        (event: any) => {
            setIsCompanySizeInvalid(false)
            setFormValue({ ...form, size: event.target.value })
        },
        [form]
    )

    const handleRoleChange = useCallback(
        (event: any) => {
            setFormValue({ ...form, role: event.target.value })
        },
        [form]
    )

    const handleCompanyNameChange = useCallback(
        (event: any) => {
            // Allow only URL safe chars
            const onInputBlockedChars = /[^\w\s\-_$.+!*'(),]/gi

            setIsCompanyNameInvalid(false)
            setErrorMsg('')

            const text = event.target.value.replace(onInputBlockedChars, '')
            if (!text.trim()) {
                setIsCompanyNameInvalid(true)
                setErrorMsg(t(tKeys.aCompanyNameIsRequired))
            }
            setFormValue({ ...form, name: text })
            setCompanyTyped(true)
        },
        [form, t]
    )

    if (loading || loadingOrg) return <HuddleLoader />

    return (
        <form onSubmit={onSubmit}>
            <HuddleHeading>
                {t(
                    flowType === FlowType.CreateSalesfloor
                        ? tKeys.setupYourSalesfloor
                        : tKeys.setupYourCompany
                )}
            </HuddleHeading>
            <Stack space="space16">
                <ItemWithMessage
                    level="danger"
                    message={
                        nameTaken
                            ? t(tKeys.companyNameAlreadyExists)
                            : t(tKeys.companyNameRequired)
                    }
                    show={isCompanyNameInvalid || nameTaken}
                    data-testid="tf.signup.org-form.invalid-name-error-message"
                >
                    <Input
                        label={t(tKeys.yourCompany)}
                        value={form.name}
                        onChange={handleCompanyNameChange}
                        disabled={loading}
                        isRequired
                        data-testid="signup-company-name"
                        maxLength={MAX_ORG_NAME_LEN}
                    />
                </ItemWithMessage>

                {showPhoneNumber && (
                    <ItemWithMessage
                        level="danger"
                        message={t(tKeys.pleaseEnterAValidPhoneNumber)}
                        show={isPhoneInvalid}
                    >
                        <PhoneNumberInput
                            value={form.phone}
                            onChange={handlePhoneNumberChange}
                            label={t(tKeys.phone)}
                            required={true}
                        />
                    </ItemWithMessage>
                )}
                <ItemWithMessage
                    level="danger"
                    message={t(tKeys.pleaseSelectCompanySize)}
                    show={isCompanySizeInvalid}
                >
                    <Select
                        label={t(tKeys.companySize)}
                        value={form.size}
                        onChange={handleCompanySizeChange}
                        isRequired={true}
                        data-testid="signup-company-size"
                    >
                        <option value="">{t(tKeys.chooseOne)}</option>
                        <option value={OrgSize.TINY}>{OrgSize.TINY}</option>
                        <option value={OrgSize.SMALL}>{OrgSize.SMALL}</option>
                        <option value={OrgSize.MEDIUM}>{OrgSize.MEDIUM}</option>
                        <option value={OrgSize.LARGE}>{OrgSize.LARGE}</option>
                        <option value={OrgSize.GIANT}>{OrgSize.GIANT}</option>
                    </Select>
                </ItemWithMessage>

                {!hideRole.enabled && (
                    <Select
                        label={t(tKeys.yourRole)}
                        value={form.role}
                        onChange={handleRoleChange}
                    >
                        <option>{t(tKeys.chooseOne)}</option>
                        <option value="product manager">
                            {t(tKeys.productManager)}
                        </option>
                        <option value="engineer">{t(tKeys.engineer)}</option>
                        <option value="designer">{t(tKeys.designer)}</option>
                        <option value="management">{t(tKeys.manager)}</option>
                        <option value="ceo">{t(tKeys.ceo)}</option>
                        <option value="marketing">{t(tKeys.marketing)}</option>
                        <option value="other">{t(tKeys.other)}</option>
                    </Select>
                )}
                <ItemWithMessage
                    level="danger"
                    light
                    message={errorMsg}
                    show={!!errorMsg && !!form.name && !nameTaken}
                >
                    <Button
                        isFullWidth
                        size="lg"
                        onClick={onSubmit}
                        disabled={!form.name || !form.size}
                        data-testid={'signup-company-submit'}
                    >
                        {t(tKeys.continue)}
                    </Button>
                </ItemWithMessage>
            </Stack>
        </form>
    )
})
