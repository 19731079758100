import React from 'react'

import { api } from '@teamflow/client-api'
import { LogManager } from '@teamflow/lib'
import * as shared from '@teamflow/lib/src/shared-old'
import { RecurringEvent } from '@teamflow/types'

export function useOnboardingMeetings(googleSSO?: boolean) {
    const [update, setUpdate] = React.useState<number>(0)
    const mutate = React.useCallback(() => setUpdate((u) => ++u), [])
    const [onboardingMeetings, setOnboardingMeetings] = React.useState<{
        events?: Record<string, Array<RecurringEvent>>
        error?: boolean
        loading: boolean
    }>({ loading: false })

    React.useEffect(() => {
        if (googleSSO) {
            setOnboardingMeetings({ loading: false })
        } else {
            setOnboardingMeetings({ loading: true })
            fetchOnboardingEvents()
                .then((data) =>
                    setOnboardingMeetings({
                        events: data.state,
                        error: data.error,
                        loading: false,
                    })
                )
                .catch(() => setOnboardingMeetings({ loading: false }))
        }
    }, [googleSSO, update])

    return { ...onboardingMeetings, mutate }
}

function fetchOnboardingEvents() {
    return api.user.calendar.getOnboardingEvents().then(({ data, error }) => {
        if (error) {
            return { error: true }
        }
        // Throw if there are no events. This will make <MeetingMigration /> auto continue.
        if (!data || !data.events || !data.events.length) {
            throw new Error('No events to schedule')
        }

        return { state: categorizeEvents(data.events), error: false }
    })
}

export function categorizeEvents(events: RecurringEvent[]) {
    const categories = Object.keys(shared.EVENT_CATEGORY_KEYWORDS)
    // An object with the category keywords mapped to empty arrays
    const state: Record<string, Array<RecurringEvent>> = categories.reduce(
        (record, key) => ({ ...record, [key]: [] }),
        {}
    )

    const seen: Set<string> = new Set()

    for (const event of events) {
        if (seen.has(event.summary)) {
            continue
        } else {
            seen.add(event.summary)
        }

        let category = categories.find((cat) =>
            shared.EVENT_CATEGORY_KEYWORDS[cat].some((keyword) =>
                event.summary.toLowerCase().includes(keyword)
            )
        )

        if (!category) {
            LogManager.ui.info('No category found for', event)
            category = 'Other meetings'
        }

        // This doesn't come from the API, silly :)
        event.checked = true

        state[category] = state[category] || []
        state[category].push(event)
    }

    return { ...state }
}

export function findDuplicateEvents(events: RecurringEvent[]) {
    const eventForApi: Array<{
        eventId: string
        calendarId: string
    }> = []

    const duplicates: Record<string, typeof eventForApi> = {}

    for (const event of events) {
        if (duplicates[event.summary]) {
            duplicates[event.summary].push({
                eventId: event.id,
                calendarId: event.calendarId,
            })
        } else {
            duplicates[event.summary] = [
                {
                    eventId: event.id,
                    calendarId: event.calendarId,
                },
            ]
        }
    }

    return duplicates
}
