import { useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useFeatureFlag } from '@teamflow/bootstrap'
import {
    Box,
    Button,
    Column,
    Columns,
    Icon,
    Link,
    Text,
} from '@teamflow/design'
import allTKeys from '@teamflow/translations'
import { Feature } from '@teamflow/types'
import {
    track,
    Signup,
    eventWithFlow,
} from '@teamflow/web/src/helpers/analytics'

import { SUPPORT_EMAIL } from '../../constants'
import { reload } from '../../helpers/reload'

import HuddleHeading from '../common/HuddleHeading'

const translationPage = 'signup'
const tKeys = allTKeys.signup.chooseInviteFromEmail

interface Props {
    email?: string
    kind?: 'signup' | 'guest'
    org?: string
    resendChooseInviteEmail: () => void
    dismissSignup?: () => void
    source?: string
}

export default function ChooseInviteFromEmail({
    email,
    kind = 'signup',
    org,
    resendChooseInviteEmail,
    dismissSignup,
    source = 'signup',
}: Props): JSX.Element {
    const { t } = useTranslation(translationPage)

    function handleContinue() {
        track(eventWithFlow(Signup.JoinAsGuestCompanyAlreadyHasTF, kind))
        if (dismissSignup) {
            dismissSignup()
        } else {
            reload('Clicked on continue in ChooseInviteFromEmail')
        }
    }

    const trackContactSupport = useCallback(() => {
        track(eventWithFlow(Signup.ContactSupportCompanyAlreadyHasTF, source))
    }, [source])

    const { enabled: allowContinueAsGuest } = useFeatureFlag(
        Feature.SameDomainContinueGuest
    )

    const handleResendEmailClick = useCallback(() => {
        track(eventWithFlow(Signup.ResendEmailCompanyAlreadyHasTF, source))
        resendChooseInviteEmail()
    }, [source, resendChooseInviteEmail])

    const message =
        kind === 'signup'
            ? t(tKeys.looksLikeYourCompanyHasATeamflowAlready)
            : org
            ? t(tKeys.joinTheRestOfYourTeamAtOrg, { org })
            : t(tKeys.joinTheRestOfYourTeam)

    useEffect(() => {
        track(eventWithFlow(Signup.CompanyAlreadyHasTeamflowShown, source))
    }, [source])

    const openOutlook = useCallback(() => {
        if (!email) return
        const url = `https://outlook.live.com/mail/?login_hint=${encodeURIComponent(
            email
        )}`
        window.open(url, '_blank')
    }, [email])

    const openGmail = useCallback(() => {
        if (!email) return
        const url = `https://mail.google.com/mail/u/${email}/#search/from%3A(${encodeURIComponent(
            SUPPORT_EMAIL
        )})+in%3Aanywhere+newer_than%3A1h`
        window.open(url, '_blank')
    }, [email])

    return (
        <>
            <HuddleHeading>{message}</HuddleHeading>
            <Text size="textSize14" color="neutral60" weight="500">
                <Trans
                    t={t}
                    i18nKey={
                        tKeys.verifyYourEmailToCompleteYourRegistrationAnEmailHasBeenSentToEmail
                    }
                >
                    <b>{{ email }}</b>
                </Trans>
            </Text>
            <Box marginTop="space32">
                <Columns space="space72">
                    <Column>
                        <Button
                            kind="text"
                            level="neutral"
                            size="md"
                            leadingIcon={<Icon name="outlookCalendar" />}
                            onClick={openOutlook}
                        >
                            Open Outlook
                        </Button>
                    </Column>
                    <Column>
                        <Button
                            kind="text"
                            level="neutral"
                            size="md"
                            leadingIcon={<Icon name="gmail" />}
                            onClick={openGmail}
                        >
                            Open Gmail
                        </Button>
                    </Column>
                </Columns>
            </Box>
            <Button
                size="lg"
                isFullWidth
                marginTop="space32"
                onClick={handleResendEmailClick}
                kind="outline"
            >
                {t(tKeys.resendEmail)}
            </Button>
            {kind === 'guest' && allowContinueAsGuest ? (
                <Box marginTop="space32" marginBottom="space32">
                    <Text align="center" width="fill">
                        <Link level="neutral" onClick={handleContinue}>
                            {t(tKeys.continueAsGuest)}
                        </Link>
                    </Text>
                </Box>
            ) : (
                <Box marginTop="space32" marginBottom="space32">
                    <Text align="center" width="fill">
                        <Link
                            onClick={trackContactSupport}
                            level="neutral"
                            href="mailto:support@teamflowhq.com"
                        >
                            {t(tKeys.contactSupport)}
                        </Link>
                    </Text>
                </Box>
            )}
        </>
    )
}
