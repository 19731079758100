export const pluralize = (str: string, num: number) => {
    switch (num) {
        case 1:
            return str

        default:
            return `${str}s`
    }
}

export const unpluralize = (str: string, num: number) => {
    switch (num) {
        case 1:
            return `${str}s`

        default:
            return str
    }
}
