import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    Checkbox,
    Scrollbar,
    Stack,
    Text,
    usePreventWheel,
} from '@teamflow/design'
import allTKeys from '@teamflow/translations'
import * as t from '@teamflow/types'

import { analytics } from '../../helpers'

import Avatar from './Avatar'

const translationPage = 'common'
const tKeys = allTKeys.common.inviteChecklist

export type InviteChecklistItem = {
    name: string
    email: string
    image: string
    checked: boolean
    role?: t.Role // todo make required
}

type Props = {
    list: InviteChecklistItem[]
    setList: (list: InviteChecklistItem[]) => void
}

export default function InviteChecklist({ list, setList }: Props): JSX.Element {
    const { t } = useTranslation(translationPage)

    const [scrollable, setScrollable] = useState<HTMLElement | null>(null)

    usePreventWheel(scrollable)

    const allChecked = list.length > 0 && list.every((item) => item.checked)

    function onSelectAllChange(): void {
        const shouldCheckAll = !allChecked

        analytics.track(
            shouldCheckAll
                ? analytics.Signup.LEGACY_SelectAllInvitees
                : analytics.Signup.LEGACY_UnselectAllInvitees
        )

        setList(
            list.map((item) => ({
                ...item,
                checked: shouldCheckAll,
            }))
        )
    }

    function listItemChangeHandler(listItem: InviteChecklistItem) {
        analytics.track(analytics.Signup.LEGACY_ModifyInvitee)

        return (checked: boolean) => {
            setList(
                list.map((item) => {
                    if (item === listItem) {
                        return { ...item, checked }
                    }
                    return item
                })
            )
        }
    }

    const indeterminate = !allChecked && list.some((item) => item.checked)

    // TODO: Upgrade to @chakra-ui/react@1.3 for <Table /> components
    return (
        <table
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}
        >
            <thead
                style={{
                    borderTop: '1px solid rgba(65, 65, 65, 0.3)',
                    borderBottom: '1px solid rgba(65, 65, 65, 0.3)',
                }}
            >
                <tr
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <th>
                        <Box
                            display="flex"
                            alignItems="center"
                            paddingLeft="space4"
                            paddingY="space10"
                            gap="space16"
                        >
                            <Checkbox
                                checked={allChecked}
                                indeterminate={indeterminate}
                                onChange={onSelectAllChange}
                            />
                            <Text
                                color="neutral80"
                                size="textSize14"
                                weight="500"
                                truncate
                            >
                                {t(tKeys.name)}
                            </Text>
                        </Box>
                    </th>
                    {/*<th
                style={{
                    width: '30px',
                    marginRight: '100px'
                }}
            >
                <Text
                    fontFamily="Inter"
                    fontSize="14px"
                    color={light ? 'grayTwo' : 'white'}
                    ml="16px"
                    fontWeight={500}
                    width="30px"
                >
                    Role
                </Text>
            </th>*/}
                </tr>
            </thead>
            <Scrollbar
                as="tbody"
                ref={setScrollable}
                style={{
                    display: 'block',
                    height: '324px',
                    maxHeight: '324px',
                    width: '100%',
                }}
            >
                {list.map((item) => {
                    return (
                        <tr
                            key={item.email}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <td style={{ width: '100%' }}>
                                <InviteCheckbox
                                    invitee={item}
                                    onChangeInvite={listItemChangeHandler(item)}
                                />
                            </td>
                            {/* TODO: Add role item change callback
                            <td
                                style={{
                                    width: '70px',
                                    marginRight: '45px'
                                }}
                            >
                                <InviteRoleChooser
                                    invitee={item}
                                    light={light}
                                />
                            </td>*/}
                        </tr>
                    )
                })}
            </Scrollbar>
        </table>
    )
}

function InviteCheckbox({
    invitee,
    onChangeInvite,
}: {
    invitee: InviteChecklistItem
    onChangeInvite: (checked: boolean) => void
}) {
    return (
        <Box display="flex" alignItems="center" paddingLeft="space4">
            <Checkbox checked={invitee.checked} onChange={onChangeInvite} />
            <Avatar
                src={invitee.image}
                name={invitee.name}
                size="sm"
                style={{
                    marginLeft: '16px',
                    marginRight: '8px',
                    flexShrink: 0,
                }}
            />
            <Box
                flexGrow={1}
                flexShrink={1}
                paddingY="space12"
                overflow="hidden"
            >
                <Stack space="space4">
                    <Text
                        color="neutral80"
                        size="textSize14"
                        weight="500"
                        truncate
                    >
                        {invitee.name}
                    </Text>
                    <Text color="neutral60" size="textSize12" truncate>
                        {invitee.email}
                    </Text>
                </Stack>
            </Box>
        </Box>
    )
}

/*
function InviteRoleChooser({
    invitee,
    light,
}: {
    invitee: InviteChecklistItem
    light: boolean
}) {
    return (
        <Menu isLazy={true}>
            <MenuButton>
                <InviteText light={light}>
                    {t.RoleOps.toString(invitee.role)}
                </InviteText>
                <ChevronDownIcon color="grayFour" fontSize="16px" verticalAlign="sub" />
            </MenuButton>
            <MenuList
                style={{
                    marginLeft: '-32.5px',
                    minWidth: '130px',
                    width: '130px',
                }}
            >
                <MenuItem>
                    <InviteText light={light}>User</InviteText>
                </MenuItem>
                <MenuItem>
                    <InviteText light={light}>Admin</InviteText>
                </MenuItem>
            </MenuList>
        </Menu>
    )
}
*/

/*
function InviteText({
    children,
    light,
    ...textProps
}: React.PropsWithChildren<
    TextProps & { light: boolean }
>) {
    return (
        <Text
            as="span"
            color={light ? 'grayTwo' : 'grayFour'}
            fontSize="12px"
            lineHeight="15px"
            {...textProps}
        >
            {children}
        </Text>
    )
}
*/
