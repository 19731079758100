import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react'
import Router, { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useFeatureFlag } from '@teamflow/bootstrap'
import { api } from '@teamflow/client-api'
import {
    Box,
    Button,
    Checkbox,
    Column,
    Columns,
    Icon,
    Input,
    ItemWithMessage,
    Label,
    Link,
    Stack,
    Text,
} from '@teamflow/design'
import { LogManager } from '@teamflow/lib'
import { LocalUser } from '@teamflow/store'
import allTKeys from '@teamflow/translations'
import * as types from '@teamflow/types'
import { Experiment, SignupOp } from '@teamflow/types'
import Feature from '@teamflow/types/src/verse/flags'

import {
    track,
    Signup,
    Flow as AnalyticsFlow,
    eventWithFlow,
} from '../../helpers/analytics'
import getAppDownloadUrl from '../../helpers/appDownload'
import { isValidEmail } from '../../helpers/email'
import { requestPermissions } from '../../helpers/google'
import { useOptimizelyTest } from '../../hooks/useABTest'
import { verifyEmail } from '../../requests'

import GoogleButton from '../common/GoogleButton'
import HuddleDivider from '../common/HuddleDivider'
import HuddleHeading from '../common/HuddleHeading'
import SlackInviteButton from '../common/SlackInviteButton'
import { InviteChecklistItem } from '../common/invite-checklist'
import InviteCreditsModal from '../experiments/InviteCreditsModal'
import InviteCreditsScreen from '../experiments/InviteCreditsScreen'

import InvitePanel from './invitePanel'

import type { ChangeEvent, FormEvent, MouseEvent } from 'react'

const translationPage = 'signup'
const tKeys = allTKeys.signup.invite

interface Props {
    userOrg: LocalUser | null
    loading: boolean
    inviteCode?: string
    onContinue: () => void
    setSignupOp?: (op: SignupOp) => void
    source: AnalyticsFlow
}

const APP_URL = process.env.NEXT_PUBLIC_APP_URL

enum InviteCreditState {
    Inactive,
    Modal,
    Active,
}

// This only works if the user created the org (not for /join flows)
function useEmailVerified(
    email: string | undefined,
    accountId: string | undefined,
    currentOrgCreatedBy: string | undefined
) {
    const [emailVerified, setEmailVerified] = React.useState<
        boolean | undefined
    >()

    useEffect(() => {
        if (!accountId) return
        if (!email || accountId !== currentOrgCreatedBy) {
            // Don't verify email if the user didn't create this organization.
            setEmailVerified(false)
        } else {
            void verifyEmail(email).then((res) => {
                if (!res) return
                const { status } = res
                const verified = status === 'valid' || status === 'accept_all'

                setEmailVerified(verified)
            })
        }
    }, [accountId, currentOrgCreatedBy, email])

    return [emailVerified, emailVerified === undefined]
}

export default observer(function Invite({
    loading,
    userOrg,
    inviteCode,
    onContinue,
    setSignupOp,
    source,
}: Props): JSX.Element {
    const { t } = useTranslation(translationPage)
    const router = useRouter()
    const [errorMsg, setErrorMsg] = useState('')
    const [slackLoading, setSlackLoading] = useState(false)
    const [slackList, setSlackList] = useState<InviteChecklistItem[]>([])
    const [emailVerified, loadingEmailVerification] = useEmailVerified(
        userOrg?.email,
        userOrg?.accountId,
        userOrg?.currentOrgCreatedBy
    )
    const [orgDomainCanJoin, setOrgDomainCanJoin] = useState<boolean>(false)
    const [googleUsers, setGoogleUsers] = useState<InviteChecklistItem[]>([])
    const [loadingGoogleUsers, setLoadingGoogleUsers] = useState(false)
    const { enabled: slackInviteTeam } = useFeatureFlag(Feature.SlackInviteTeam)

    const { accessToken, domain } = router.query as {
        accessToken?: string
        domain?: string
    }

    const showGoogleInvite = Boolean(accessToken && domain)

    const inviteWithGoogle = useCallback(async () => {
        if (!accessToken || !domain) {
            return
        }

        setLoadingGoogleUsers(true)

        try {
            await requestPermissions('invite')
        } catch (e) {
            // Denying permission will typically redirect to logout, so this is just in case
            setLoadingGoogleUsers(false)
            return
        }

        const { data, error } = await api.auth.google.users({
            accessToken,
            domain,
        })
        if (error) {
            Sentry.captureException(error, {
                tags: {
                    is_signup: true,
                    page: 'invite',
                },
            })
        }
        const users = (data ?? []) as InviteChecklistItem[]

        if (users) {
            users.forEach((user: InviteChecklistItem) => {
                user.checked = true
                user.role = types.Role.USER
            })
        }

        setGoogleUsers(users)
        setLoadingGoogleUsers(false)

        track(Signup.LEGACY_InviteSelectGoogle)
    }, [accessToken, domain])

    useEffect(() => setOrgDomainCanJoin(!!emailVerified), [emailVerified])

    const goToVerse = useCallback(async () => {
        if (userOrg?.email && orgDomainCanJoin) {
            await api.organization.patch({
                orgId: userOrg.currentOrgId,
                updates: {
                    emailDomain: `${userOrg.email.split('@')[1]}`,
                },
            })
            // Organization's emailDomain will only update once email is confirmed! We don't have to
            // do anything after this step!!!!
            if (!userOrg.emailConfirmed) {
                await api.email.confirm()
            }
        }

        if (getAppDownloadUrl() && setSignupOp) {
            onContinue()
        } else {
            await Router.push(`/redirect`)
        }
    }, [onContinue, orgDomainCanJoin, setSignupOp, userOrg])

    const sendInvites = useCallback(
        async (emailsToSend: string[]) => {
            const { data, error } = await api.email.inviteToOrg({
                emails: emailsToSend.map((email) => ({ email })),
            })
            if (error) {
                Sentry.captureException(error, {
                    tags: {
                        is_signup: true,
                        page: 'invite',
                    },
                })
            }
            if (data && data.ok) {
                await goToVerse()
            } else {
                const errorMessage = t(tKeys.failedToSendInvitesPleaseTryAgain)
                setErrorMsg(errorMessage)
                track('invite_error', {
                    errorMessage,
                })
            }
        },
        [goToVerse, t]
    )

    async function onSlackConnected() {
        setSlackLoading(true)
        const { data, error } = await api.slack.listTeam()
        if (data && data.length) {
            setSlackList(
                data
                    .filter((item) => {
                        return item.email !== userOrg?.email
                    })
                    .map((item) => ({
                        ...item,
                        checked: true,
                        role: types.Role.USER,
                    }))
            )
        } else {
            Sentry.captureException(error, {
                tags: {
                    is_signup: true,
                    page: 'invite',
                },
            })
            LogManager.ui.log('onSlackConnected error', error)
            setErrorMsg(t(tKeys.failedToLoadTeamFromSlackPleaseTryAgain))
        }
        setSlackLoading(false)
    }

    const onSubmitList = useCallback(
        async (list: InviteChecklistItem[]) => {
            const checkedEmails = list
                .filter((item) => item.checked)
                .map((item) => item.email)
            await sendInvites(checkedEmails)
        },
        [sendInvites]
    )

    const handleInviteGoogleSubmit = useCallback(async () => {
        await onSubmitList(googleUsers)
        track(Signup.LEGACY_InviteWithGoole)
    }, [googleUsers, onSubmitList])

    const handleInviteGoogleCancel = useCallback(() => {
        track(Signup.LEGACY_Skip, {
            step: 'invite',
            kind: 'google',
        })
        setGoogleUsers([])
    }, [])

    const handleInviteSlackSubmit = useCallback(() => {
        return onSubmitList(slackList)
    }, [onSubmitList, slackList])

    const handleInviteSlackCancel = useCallback(() => {
        track(Signup.LEGACY_Skip, {
            step: 'invite',
            kind: 'slack',
        })
        setSlackList([])
    }, [])

    useEffect(() => {
        track(eventWithFlow(Signup.InviteScreenShown, source))
    }, [source])

    // TODO: Swapping this out for a HuddleLoader breaks the e2e test
    if (!userOrg || loading || loadingGoogleUsers || loadingEmailVerification) {
        return <div>{t(tKeys.loading)}</div>
    }

    const formFooter =
        (emailVerified && (
            <OrgDomainForm
                orgDomainCanJoin={orgDomainCanJoin}
                setOrgDomainCanJoin={setOrgDomainCanJoin}
                email={userOrg.email ?? ''}
            />
        )) ||
        undefined

    if (googleUsers.length > 0) {
        return (
            <InvitePanel
                formFooter={formFooter}
                heading={t(tKeys.inviteYourTeam)}
                list={googleUsers}
                setList={setGoogleUsers}
                onSubmit={handleInviteGoogleSubmit}
                onCancel={handleInviteGoogleCancel}
            />
        )
    }

    if (slackList.length > 0) {
        return (
            <InvitePanel
                formFooter={formFooter}
                heading={t(tKeys.inviteYourTeamViaSlack)}
                list={slackList}
                setList={setSlackList}
                onSubmit={handleInviteSlackSubmit}
                onCancel={handleInviteSlackCancel}
            />
        )
    }

    return (
        <InviteManualForm
            slackInviteTeam={!!slackInviteTeam}
            errorMsg={errorMsg}
            formFooter={formFooter}
            inviteCode={inviteCode}
            slackLoading={slackLoading}
            loadingGoogleUsers={loadingGoogleUsers}
            onContinue={goToVerse}
            onSlackConnected={onSlackConnected}
            onInviteWithGoogle={inviteWithGoogle}
            showGoogleInvite={showGoogleInvite}
            setErrorMsg={setErrorMsg}
            userOrg={userOrg}
            source={source}
        />
    )
})

const InviteManualForm = observer(function InviteManualForm({
    slackInviteTeam,
    errorMsg,
    formFooter,
    inviteCode,
    slackLoading,
    loadingGoogleUsers,
    onContinue,
    onSlackConnected,
    onInviteWithGoogle,
    showGoogleInvite,
    setErrorMsg,
    userOrg,
    source = AnalyticsFlow.None,
}: {
    slackInviteTeam: boolean
    errorMsg: string
    formFooter?: JSX.Element
    inviteCode?: string
    slackLoading: boolean
    loadingGoogleUsers: boolean
    onContinue: () => void
    onSlackConnected: () => void
    onInviteWithGoogle: () => void
    showGoogleInvite: boolean
    setErrorMsg: (errorMsg: string) => void
    userOrg: LocalUser
    source: AnalyticsFlow
}) {
    const { t } = useTranslation(translationPage)

    const joinUrl = `${APP_URL}/join/${userOrg.currentOrgSlug}/${inviteCode}`

    const [copyToClipboard, setCopyToClipboard] = useState(false)
    const [focusedOn, setFocusedOn] = useState(0)
    const [emails, setEmailValue] = React.useState([''])
    const { active: inviteCreditActive } = useOptimizelyTest(
        Experiment.InviteCredit,
        userOrg?._id,
        {
            attributes: { role: userOrg?.role ?? 0 },
        }
    )
    const [inviteCreditState, setInviteCreditState] = useState(
        InviteCreditState.Inactive
    )

    const validateEmails = (emails: string[]): boolean => {
        let isInvalid = false
        emails.some((email: string) => {
            if (!email.length) return

            const valid = isValidEmail(email)
            if (!valid) {
                isInvalid = true
                return true
            }
        })
        return isInvalid
    }

    const emailsInvalid = validateEmails(emails)

    const addEmailRow = useCallback(() => {
        const newEmails = [...emails, '']
        setEmailValue(newEmails)

        track(eventWithFlow(Signup.InviteAddTeamEmail, source))
    }, [emails, source])

    const onEmailChange = useCallback(
        (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
            const newEmails = [...emails]
            newEmails[index] = e.currentTarget.value

            if (index === newEmails.length - 1) {
                newEmails.push('')
                track(eventWithFlow(Signup.InviteAddTeamEmail, source))
            }

            setEmailValue(newEmails)
        },
        [emails, source]
    )

    const copyToClipBoard = useCallback(async () => {
        await navigator.clipboard.writeText(joinUrl)
        setCopyToClipboard(true)
        setTimeout(() => {
            setCopyToClipboard(false)
        }, 3000)

        track(eventWithFlow(Signup.InviteCopyLink, source))
    }, [joinUrl, source])

    const handleKeyUp = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter' && !!e.currentTarget.value) {
                setFocusedOn(emails.length - 1)
                addEmailRow()
            }
        },
        [addEmailRow, emails.length]
    )

    const sendInvites = useCallback(
        async (emailsToSend: string[]) => {
            const { data, error } = await api.email.inviteToOrg({
                emails: emailsToSend.map((email) => ({ email })),
            })
            if (error) {
                Sentry.captureException(error, {
                    tags: {
                        is_signup: true,
                        page: 'invite',
                    },
                })
            }
            if (data && data.ok) {
                track(eventWithFlow(Signup.InvitesSent, source), {
                    emails: emailsToSend.join(),
                })
                onContinue()
            } else {
                const errorMessage = t(tKeys.failedToSendInvitesPleaseTryAgain)
                setErrorMsg(errorMessage)
                track('invite_error', {
                    errorMessage,
                })
            }
        },
        [onContinue, setErrorMsg, source, t]
    )

    const onSubmit = useCallback(
        async (e: FormEvent<HTMLFormElement> | MouseEvent<HTMLElement>) => {
            e.preventDefault()

            if (emailsInvalid) {
                track('invite_error', {
                    errorMessage: 'Invalid emails',
                })
                setErrorMsg(t(tKeys.checkEmailAddresses))
                return
            }

            if (!emails[0].length) {
                onContinue()
                return
            }

            const emailsToSend = emails.filter((email) => !!email)
            await sendInvites(emailsToSend)
        },
        [emails, emailsInvalid, onContinue, sendInvites, setErrorMsg, t]
    )

    const onSkip = React.useCallback(() => {
        track(eventWithFlow(Signup.SkipInvite, source))

        if (inviteCreditActive) {
            setInviteCreditState(InviteCreditState.Modal)
        } else {
            onContinue()
        }
    }, [source, inviteCreditActive, onContinue])

    const handleInviteCreditsModalConfirm = useCallback(() => {
        setInviteCreditState(InviteCreditState.Active)
    }, [])

    if (inviteCreditState === InviteCreditState.Active) {
        return <InviteCreditsScreen onContinue={onContinue} />
    }

    const hasInviteButtons = showGoogleInvite || slackInviteTeam

    return (
        <>
            <HuddleHeading>{t(tKeys.inviteYourTeam)}</HuddleHeading>
            {hasInviteButtons && (
                <>
                    <Columns space="space12" width="fill">
                        {showGoogleInvite && (
                            <Column>
                                <GoogleButton
                                    isLoading={loadingGoogleUsers}
                                    onClick={onInviteWithGoogle}
                                >
                                    {t(tKeys.inviteViaGoogle)}
                                </GoogleButton>
                            </Column>
                        )}
                        {slackInviteTeam && (
                            <Column>
                                <SlackInviteButton
                                    id={userOrg.accountId}
                                    onConnected={onSlackConnected}
                                    isLoading={slackLoading}
                                    buttonLocation="signup"
                                >
                                    {t(tKeys.inviteViaSlack)}
                                </SlackInviteButton>
                            </Column>
                        )}
                    </Columns>
                    <HuddleDivider />
                </>
            )}
            <Box width="fill" marginBottom="space12">
                <Label>{t(tKeys.inviteViaEmail)}</Label>
            </Box>
            <Stack space="space16" width="fill">
                {emails.map((value, index) => {
                    const lastItem = index + 1 === emails.length

                    return (
                        <ItemWithMessage
                            key={index}
                            level="danger"
                            message={t(tKeys.pleaseEnterAValidEmail)}
                            show={!!errorMsg && !!value && !isValidEmail(value)}
                            data-testid="tf.signup.invite-team.invalid-email-error"
                        >
                            <Input
                                value={value}
                                onChange={onEmailChange(index)}
                                type="email"
                                isInvalid={
                                    !!errorMsg &&
                                    !!value &&
                                    !isValidEmail(value)
                                }
                                autoFocus={focusedOn === index}
                                data-testid="tf.signup.invite-team.email-input"
                                aria-describedby="email-helper-text"
                                placeholder="bob@company.com"
                                onKeyUp={lastItem ? handleKeyUp : undefined}
                            />
                        </ItemWithMessage>
                    )
                })}
            </Stack>
            <Box width="fill" marginTop="space4">
                <Button
                    kind="text"
                    size="xs"
                    leadingIcon={
                        <Icon name="plus" color="primary" size="size16" />
                    }
                    onClick={addEmailRow}
                >
                    {t(tKeys.inviteOneMoreTeammate)}
                </Button>
            </Box>
            {inviteCode && (
                <>
                    <Box
                        width="fill"
                        marginTop="space16"
                        marginBottom="space12"
                    >
                        <Label>{t(tKeys.inviteViaLink)}</Label>
                    </Box>
                    <Input
                        data-testid="tf.signup.invite.invite-via-link-input"
                        readOnly
                        value={joinUrl}
                        type="text"
                        onClick={copyToClipBoard}
                        textOverflow="ellipsis"
                        aria-describedby="invite-link-text"
                        width="fill"
                        after={
                            <Button
                                kind="text"
                                size="sm"
                                onClick={copyToClipBoard}
                            >
                                {copyToClipboard
                                    ? t(tKeys.copied)
                                    : t(tKeys.copyLink)}
                            </Button>
                        }
                    />
                </>
            )}

            {formFooter}

            <ItemWithMessage
                level="danger"
                light
                message={errorMsg}
                show={!!errorMsg}
                width="fill"
            >
                <Button
                    size="lg"
                    isFullWidth
                    data-testid="tf.signup.invite-team.submit-btn"
                    onClick={onSubmit}
                    marginTop="space24"
                >
                    {t(tKeys.inviteYourTeam)}
                </Button>
            </ItemWithMessage>

            <Box marginTop="space16" marginBottom="space16">
                <Text align="center" width="fill">
                    <Link
                        data-testid="tf.signup.invite-team.later-btn"
                        level="neutral"
                        onClick={onSkip}
                    >
                        {t(tKeys.iLlDoThisLater)}
                    </Link>
                </Text>
            </Box>

            <InviteCreditsModal
                isOpen={inviteCreditState === InviteCreditState.Modal}
                onClose={onContinue}
                onConfirm={handleInviteCreditsModalConfirm}
            />
        </>
    )
})

function OrgDomainForm({
    orgDomainCanJoin,
    setOrgDomainCanJoin,
    email,
}: {
    orgDomainCanJoin: boolean
    setOrgDomainCanJoin: (orgDomainCanJoin: boolean) => void
    email: string
}) {
    const { t } = useTranslation(translationPage)

    const handleCheckboxChange = useCallback(
        (checked) => {
            setOrgDomainCanJoin(checked)
        },
        [setOrgDomainCanJoin]
    )

    return (
        <Box
            background="neutral20"
            borderRadius="borderRadius8"
            marginTop="space24"
            padding="space24"
            width="fill"
            __cssOverrides={{
                border: '0.5px solid rgba(0, 0, 0, 0.1)',
            }}
        >
            <Checkbox
                checked={orgDomainCanJoin}
                onChange={handleCheckboxChange}
                label={
                    <Trans t={t} i18nKey={tKeys.anyoneWithEmailCanJoin}>
                        <b>{{ email: `@${email.split('@')[1]}` }}</b>
                    </Trans>
                }
            />
        </Box>
    )
}
