import { useTranslation } from 'react-i18next'

import { Box, Button, Link, Text } from '@teamflow/design'
import allTKeys from '@teamflow/translations'

import HuddleHeading from '../common/HuddleHeading'
import InviteChecklist, {
    InviteChecklistItem,
} from '../common/invite-checklist'

const translationPage = 'signup'
const tKeys = allTKeys.signup.invite

type Props = {
    formFooter?: JSX.Element
    list: InviteChecklistItem[]
    setList: (list: InviteChecklistItem[]) => void
    onSubmit: () => void
    onCancel: () => void
    heading: string
}

export default function InvitePanel({
    formFooter,
    list,
    setList,
    onSubmit,
    onCancel,
    heading = 'Invite your team',
}: Props): JSX.Element {
    const { t } = useTranslation(translationPage)

    const noneChecked = list.every((item) => !item.checked)
    const numChecked = list.reduce(
        (sum, item) => (item.checked ? sum + 1 : sum),
        0
    )

    return (
        <>
            <HuddleHeading marginBottom="space48">{heading}</HuddleHeading>
            <InviteChecklist list={list} setList={setList} />
            {formFooter}

            <Button
                size="lg"
                isFullWidth
                disabled={noneChecked}
                marginTop="space24"
                onClick={onSubmit}
            >
                {numChecked
                    ? t(tKeys.inviteNumCheckedToYourTeam, { numChecked })
                    : t(tKeys.inviteYourTeam)}
            </Button>

            <Box marginTop="space16">
                <Text align="center" width="fill">
                    <Link level="neutral" onClick={onCancel}>
                        {t(tKeys.noThanksIllInviteMyTeamManually)}
                    </Link>
                </Text>
            </Box>
        </>
    )
}
