import * as Sentry from '@sentry/react'
import {
    CardElement,
    Elements,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js'
import capitalize from 'lodash/capitalize'
import { useRouter } from 'next/router'
import { FormEvent, MouseEvent, useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { api } from '@teamflow/client-api'
import {
    Box,
    Button,
    ItemWithMessage,
    Skeleton,
    Switch,
    Text,
} from '@teamflow/design'
import { LogManager } from '@teamflow/lib'
import allTKeys from '@teamflow/translations'
import { ApiErrorCode } from '@teamflow/types'
import { IProductResponse } from '@teamflow/types/src/api/billing'
import BillingForm from '@teamflow/web/src/components/common/BillingForm'
import {
    track,
    eventWithFlow,
    Flow,
    Signup,
} from '@teamflow/web/src/helpers/analytics'
import { loadStripeSafe } from '@teamflow/web/src/helpers/stripe'

import {
    getPriceId,
    initialize,
    PaymentPlan,
    PaymentTerm,
} from '../../helpers/billing'

import HuddleHeading from '../common/HuddleHeading'
import ProductCostCard from '../common/ProductCostCard'

const translationPage = 'signup'
const tKeys = allTKeys.signup.creditCard

const stripePromise = loadStripeSafe()

const DEFAULT_TRIAL_PERIOD_DAYS = 7

type Props = {
    loading: boolean
    setCustomer?: (customerId: string) => void
}

type PaymentFormProps = {
    setCustomer?: (customerId: string) => void
    coupon?: string
}

function PaymentForm(props: PaymentFormProps) {
    const { t } = useTranslation(translationPage)

    const { setCustomer, coupon } = props

    const [errorMessage, setErrorMessage] = useState('')
    const [seats, setSeats] = useState(1)

    const [product, setProduct] = useState<IProductResponse>()
    const [processing, setProcessing] = useState(false)

    const [address, setAddress] = useState({
        line1: '',
        city: '',
        state: '',
        postal_code: '',
    })

    const [plan] = useState(PaymentPlan.Seed)
    const [term, setTerm] = useState(PaymentTerm.Monthly)

    const stripe = useStripe()
    const elements = useElements()

    const canSubmit =
        !!address.line1 &&
        !!address.city &&
        !!address.state &&
        !!address.postal_code

    const messageForSubscriptionError = useCallback(
        (error: ApiErrorCode) => {
            Sentry.captureException(error, {
                tags: {
                    is_signup: true,
                    page: 'neworg',
                },
            })
            switch (error) {
                case ApiErrorCode.PaymentFailure:
                    return t(
                        tKeys.weHadAProblemChargingThisCardPleaseTryAnotherCard
                    )
            }

            return t(tKeys.failedToCreateASubscription)
        },
        [t]
    )

    useEffect(() => {
        const retrieveProductInfo = async () => {
            setProduct(undefined)
            const priceId = await getPriceId(plan, term)

            const { data, error } = await api.billing.products.get({ priceId })
            if (error) {
                Sentry.captureException(error, {
                    tags: { is_signup: true, page: 'neworg' },
                })
            }

            setProduct(data)
        }

        void retrieveProductInfo()
    }, [plan, term])

    useEffect(() => {
        if (!elements || !stripe) {
            return
        }

        const cardElement = elements.getElement(CardElement)

        if (!cardElement) {
            return
        }

        cardElement.on('change', (evt) => {
            if (!evt.error) {
                setErrorMessage('')
                return
            }

            Sentry.captureException(evt.error, {
                tags: { is_signup: true, page: 'neworg' },
            })
            setErrorMessage(evt.error.message)
        })

        // NOTE: doesn't look like there is a .off()
    }, [elements, stripe])

    const handleSubmit = useCallback(
        async (e: FormEvent<HTMLFormElement> | MouseEvent<HTMLElement>) => {
            e.preventDefault()

            if (!stripe || !elements) {
                return
            }

            const cardElement = elements.getElement(CardElement)

            if (!cardElement) {
                LogManager.ui.error('missing card element')
                return
            }

            setProcessing(true)
            setErrorMessage('')

            const res = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            })

            const { error, paymentMethod } = res
            if (error) {
                Sentry.captureException(error, {
                    tags: {
                        is_signup: true,
                        page: 'neworg',
                    },
                })
                setErrorMessage(error.message || '')
                setProcessing(false)
                return
            }

            if (!paymentMethod) {
                setErrorMessage(t(tKeys.noPaymentMethodWasCreated))
                setProcessing(false)
                return
            }

            // create a customer
            const { data: customerData, error: customerError } =
                await api.billing.customers.create({})
            if (customerError || !customerData) {
                Sentry.captureException(customerError, {
                    tags: {
                        is_signup: true,
                        page: 'neworg',
                    },
                })
                setErrorMessage(t(tKeys.failedToCreateACustomer))
                setProcessing(false)
                return
            }

            const priceId = await getPriceId(plan, term)
            const quantity = seats

            // create a subscription with paymentMethod
            const { data: subscription, error: subscriptionError } =
                await api.billing.subscriptions.create({
                    paymentMethodId: paymentMethod.id,
                    priceId,
                    quantity,
                    address,
                    coupon,
                    trialPeriod: DEFAULT_TRIAL_PERIOD_DAYS,
                })

            if (subscriptionError) {
                Sentry.captureException(subscriptionError, {
                    tags: {
                        is_signup: true,
                        page: 'neworg',
                    },
                })
                setErrorMessage(
                    messageForSubscriptionError(subscriptionError.code)
                )
                setProcessing(false)
                return
            }

            if (!subscription) {
                setErrorMessage(t(tKeys.somethingWentWrongPleaseTryAgainLater))
                setProcessing(false)
                return
            }

            if ('clientSecret' in subscription && subscription.clientSecret) {
                const res = await stripe.confirmCardPayment(
                    subscription.clientSecret,
                    {
                        payment_method: paymentMethod.id,
                    }
                )

                if (res.error) {
                    Sentry.captureException(res.error, {
                        tags: {
                            is_signup: true,
                            page: 'neworg',
                        },
                    })
                    setErrorMessage(res.error.message as string)
                    setProcessing(false)
                    return
                }

                if (res.paymentIntent?.status !== 'succeeded') {
                    Sentry.captureException(
                        new Error('Payment not succeeded'),
                        {
                            tags: {
                                is_signup: true,
                                page: 'neworg',
                            },
                        }
                    )
                    setErrorMessage(
                        t(tKeys.somethingWentWrongPleaseTryAgainLater)
                    )
                    setProcessing(false)
                    return
                }

                if ('invoiceId' in subscription && subscription.invoiceId) {
                    // notify server that we compeleted the transaction
                    // a stripe webhook may fire as well in case the user closes this window
                    // in the middle of finishing the payment
                    const { error: completionError } =
                        await api.billing.subscriptions.complete({
                            paymentMethodId: paymentMethod.id,
                            priceId,
                            invoiceId: subscription.invoiceId,
                        })

                    if (completionError) {
                        Sentry.captureException(completionError, {
                            tags: {
                                is_signup: true,
                                page: 'neworg',
                            },
                        })
                        // this should really never happen but they will have been charged
                        // and we could not get all the data from Stripe to properly
                        // upgrade their account
                        t(
                            tKeys.subscriptionFailedPleaseContactSupportTeamflowhqCom
                        )
                        setProcessing(false)
                        return
                    }
                }
            }

            track(eventWithFlow(Signup.PaymentSubmitted, Flow.NewOrg))

            // assume everything succeeded if we get there
            if (setCustomer) {
                setCustomer(customerData.stripeCustomerId)
            }
        },
        [
            address,
            coupon,
            elements,
            messageForSubscriptionError,
            plan,
            seats,
            setCustomer,
            stripe,
            t,
            term,
        ]
    )

    const handleTermChanged = useCallback((checked) => {
        const newTerm = checked ? PaymentTerm.Yearly : PaymentTerm.Monthly
        setTerm(newTerm)
    }, [])

    return (
        <form data-testId="tf.signup.credit-card.form" onSubmit={handleSubmit}>
            <HuddleHeading>{t(tKeys.completeSubscription)}</HuddleHeading>
            <Box
                display="flex"
                alignItems="center"
                gap="space12"
                marginBottom="space32"
            >
                <Text size="textSize14">
                    <Trans t={t} i18nKey={tKeys.payYearlySave25}>
                        <Text size="textSize14" weight="700" color="primary">
                            text
                        </Text>
                    </Trans>
                </Text>

                <Switch
                    isChecked={term === PaymentTerm.Yearly}
                    onChange={handleTermChanged}
                />
            </Box>

            <Box display="flex" justifyContent="center" marginBottom="space24">
                <ProductCostCard
                    product={product}
                    coupon={coupon}
                    seats={seats}
                    term={term}
                    onSeatsChanged={setSeats}
                />
            </Box>
            <BillingForm onAddressChanged={setAddress} />

            <ItemWithMessage
                level="danger"
                light
                message={errorMessage}
                show={!!errorMessage}
                data-testid="tf.signup.credit-card.error"
            >
                <Button
                    data-testid={`tf.signup.credit-card.subscribe-button${
                        processing ? '-loading' : ''
                    }`}
                    size="lg"
                    isFullWidth
                    isLoading={processing}
                    loadingText={`Subscribe to ${capitalize(plan)}`}
                    disabled={!canSubmit}
                    marginTop="space24"
                    onClick={handleSubmit}
                >
                    Subscribe to {capitalize(plan)}
                </Button>
            </ItemWithMessage>
        </form>
    )
}

export default function PayWithCreditCard({ loading, setCustomer }: Props) {
    const router = useRouter()

    const { code } = router.query as { code?: string }

    useEffect(() => {
        // try to retrieve priceIds immediately
        void initialize()
        track(eventWithFlow(Signup.PaymentFormShown, Flow.NewOrg))
    }, [])

    if (loading) {
        return <Skeleton />
    }

    return (
        <Elements stripe={stripePromise}>
            <PaymentForm setCustomer={setCustomer} coupon={code} />
        </Elements>
    )
}
