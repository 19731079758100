import { styled } from '@stitches/react'
import PhoneInput from 'react-phone-number-input'

import 'react-phone-number-input/style.css'
import { Box, BoxProps, Input, Label, Stack } from '@teamflow/design'

type PhoneInputProps = {
    value: string
    onChange: (arg: string) => void
    required: boolean
    label: string
    background?: BoxProps['background']
}

const RequiredIcon = styled('span', {
    color: 'hsla(252, 84%, 63%, 1)',
    marginLeft: '4px',
    fontSize: '14px',
    fontWeight: '600',
})

export const PhoneNumberInput = ({
    value,
    onChange,
    required = true,
    label,
    background = 'background',
}: PhoneInputProps) => {
    return (
        <Stack space="space12" width="fill">
            <Label htmlFor="phone">
                {label}
                {required && <RequiredIcon>*</RequiredIcon>}
            </Label>
            <Box background={background} borderRadius="borderRadius6">
                <PhoneInput
                    value={value}
                    onChange={(value) => {
                        onChange(value ?? '')
                    }}
                    defaultCountry="US"
                    inputComponent={Input}
                    width="fill"
                    id="phone"
                    data-testid="signup-phone"
                />
            </Box>
        </Stack>
    )
}
