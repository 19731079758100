import optimizely from '@optimizely/optimizely-sdk'

import { waitForMilliseconds } from '@teamflow/lib/src/time/waitForSeconds'
import { Experiment, ILogger } from '@teamflow/types'

import { IABTestService, IABTest, IEventPayload } from '../../../types/abtests'

import ABTest from './ABTest'

export default class OptimizelyTestService
    implements IABTestService<Experiment>
{
    private readonly sdkKey: string
    private readonly logger?: ILogger

    private client?: optimizely.Client

    private _initialized = false

    get initialized() {
        return this._initialized
    }

    constructor(sdkKey: string, logger?: ILogger) {
        this.sdkKey = sdkKey
        this.logger = logger
    }

    async initialize() {
        if (typeof window === 'undefined') {
            // not active in ssr
            return Promise.resolve()
        }

        // don't create optimizely instance if running from a bot test
        // will eat up all our MAUs
        if (window.navigator.userAgent.indexOf('via TFBot') > -1) {
            this.logger?.warn('Optimizely skipping initialization due to TFBot')
            this._initialized = true
            return Promise.resolve()
        }

        if (this._initialized) {
            return Promise.resolve()
        }

        // always set to initialized even if the process fails
        // the service DID initialize but the underlying optimizely
        // client is not working
        if (!this.sdkKey) {
            this.logger?.warn(`missing optimizely sdk key`)
            this._initialized = true
            return Promise.resolve()
        }

        try {
            this.client = optimizely.createInstance({
                sdkKey: this.sdkKey,
                eventBatchSize: 10,
                eventFlushInterval: 1000,
                logLevel:
                    process.env.NEXT_PUBLIC_APP_ENV === 'production'
                        ? optimizely.enums.LOG_LEVEL.WARNING
                        : optimizely.enums.LOG_LEVEL.INFO,
            })

            const { success } = await this.client.onReady()
            if (!success) {
                this.logger?.warn(`optimizely failed to initialize`)
            }

            // NOTE: optimizely sdk itself will sometimes error
            // when trying to use it after onReady() without waiting a bit
            // this may be a bug on their side
            await waitForMilliseconds(300)
        } catch (e) {
            this.logger?.error('OptimizeTestService error', e)
        }

        this._initialized = true
    }

    isEnabled(
        flag: Experiment,
        userId?: string,
        payload: Record<string, any> = {}
    ) {
        if (!this.client || !userId) {
            return false
        }

        const user = this.client.createUserContext(userId, payload)
        if (!user) {
            return false
        }

        const decision = user.decide(flag, [])

        return decision.enabled
    }

    getVariation(
        flag: Experiment,
        userId?: string,
        payload: Record<string, any> = {}
    ) {
        if (!this.client || !userId) {
            return null
        }

        const user = this.client.createUserContext(userId, payload)
        if (!user) {
            return null
        }

        const decision = user.decide(flag, [])

        if (!decision.enabled) {
            return null
        }

        if (decision.variationKey === null) {
            this.logger?.error('Optimizely decision error: ', decision.reasons)
            return null
        }

        return decision.variationKey
    }

    getValue<Variables extends string>(
        flag: Experiment,
        variable?: Variables,
        userId?: string,
        payload: Record<string, any> = {}
    ): number | string | boolean | undefined | null {
        if (!this.client || !variable || !userId) {
            return false
        }

        const user = this.client.createUserContext(userId, payload)
        if (!user) {
            return false
        }

        const decision = user.decide(flag, [])

        if (!decision.enabled) {
            return false
        }

        return decision.variables[variable] as
            | number
            | string
            | boolean
            | undefined
            | null
    }

    track<Events extends string>(
        event: Events,
        userId?: string,
        payload?: IEventPayload
    ) {
        if (!this.client || !userId) {
            return
        }

        // NOTE: optimizely mentioned using our own track as well
        // if we push from segment to them
        // but... depending on what id we are using to bucket
        // it may not work (if we want to use orgId in some cases for example)
        this.client.track(event, userId, payload)
    }

    get<Variables extends string, Events extends string>(
        flag: Experiment,
        userId?: string
    ): IABTest<Variables, Events> {
        return new ABTest<Variables, Events, Experiment>(this, flag, userId)
    }
}
