import { getPublicApiUrl } from '@teamflow/lib'

const goToProviderOAuthAuthorizePage = (options: {
    apiRedirectUri: string
    scopes?: string
    clientId: string
    providerUrlPrefix: string
    onClose?: () => void
}) => {
    const apiRedirectUrl = getPublicApiUrl(options.apiRedirectUri)
    const params = new URLSearchParams(
        Object.fromEntries(
            Object.entries({
                client_id: options.clientId,
                redirect_uri: apiRedirectUrl,
                response_type: 'code',
                scope: options.scopes,
            }).filter(
                (entry): entry is [string, string] => entry[1] !== undefined
            )
        )
    )

    const url = `${options.providerUrlPrefix}?${params.toString()}`
    window.open(url, '_blank')

    options.onClose?.()
}

type OnClose = undefined | (() => void)

export function connectSalesLoft(onClose?: OnClose) {
    goToProviderOAuthAuthorizePage({
        apiRedirectUri: '/api/auth/salesloft',
        clientId: process.env.NEXT_PUBLIC_SALESFLOOR_SALESLOFT_CLIENT_ID,
        providerUrlPrefix: 'https://accounts.salesloft.com/oauth/authorize',
        onClose,
    })
}

export function connectOutreach(onClose?: OnClose) {
    goToProviderOAuthAuthorizePage({
        apiRedirectUri: '/api/auth/outreach',
        clientId: process.env.NEXT_PUBLIC_SALESFLOOR_OUTREACH_CLIENT_ID,
        providerUrlPrefix: 'https://api.outreach.io/oauth/authorize',
        // must be a space separated string
        scopes: 'webhooks.all users.read calls.read roles.read',
        onClose,
    })
}

export function connectRingCentral(onClose?: OnClose) {
    goToProviderOAuthAuthorizePage({
        apiRedirectUri: '/api/auth/ringcentral',
        clientId: process.env.NEXT_PUBLIC_SALESFLOOR_RINGCENTRAL_CLIENT_ID,
        providerUrlPrefix:
            'https://platform.ringcentral.com/restapi/oauth/authorize',
        onClose,
    })
}

export function connectJustCall(onClose?: OnClose) {
    window.open('/integration/justcall', '_blank')
    onClose?.()
}

export function connectZoom(onClose?: OnClose) {
    goToProviderOAuthAuthorizePage({
        apiRedirectUri: '/api/auth/zoom',
        clientId: process.env.NEXT_PUBLIC_SALESFLOOR_ZOOM_CLIENT_ID, // diff client id for ephemeral vs prod fyi
        providerUrlPrefix: 'https://zoom.us/oauth/authorize',
        onClose,
    })
}

export function connectDialpad(onClose: OnClose) {
    goToProviderOAuthAuthorizePage({
        apiRedirectUri: '/api/auth/dialpad',
        clientId: process.env.NEXT_PUBLIC_SALESFLOOR_DIALPAD_CLIENT_ID,
        providerUrlPrefix: 'https://dialpad.com/oauth2/authorize',
        onClose,
    })
}
