export default function openPopup(
    url: string,
    width = 800,
    height = 600
): Window | null {
    const left = (window.screen.width - width) / 2
    const top = (window.screen.height - height) / 2
    const params = `width=${width},height=${height},top=${top},left=${left}`
    const win = window.open(url, 'name', params)
    if (!win) {
        return null
    }
    win.focus()
    return win
}
