import { PropsWithChildren } from 'react'

import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalButtons,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Text,
} from '@teamflow/design'
import { getPublicUrl } from '@teamflow/lib'

type Props = {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
    actionText?: string
}

export default function CreditsModal({
    isOpen,
    onClose,
    onConfirm,
    children,
    actionText = `I'd like to earn some credit`,
}: PropsWithChildren<Props>) {
    return (
        <Modal open={isOpen} hideCloseButton isContentScrollable={false}>
            <ModalContent style={{ width: 500 }}>
                <ModalHeader>Are you sure?</ModalHeader>
                <ModalBody>
                    <Box display="flex" alignItems="center" gap="space16">
                        <img
                            src={getPublicUrl('/images/gift-round.png')}
                            width={64}
                            height={64}
                        />
                        <Box flexGrow={1}>
                            <Text>{children}</Text>
                        </Box>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <ModalButtons>
                        <Button
                            size="md"
                            level="neutral"
                            kind="text"
                            onClick={onClose}
                            data-testid="tf.signup.invite-team.experiments.credits.no-thanks"
                        >
                            No thanks, I’ll pass
                        </Button>
                        <Button size="md" onClick={onConfirm}>
                            {actionText}
                        </Button>
                    </ModalButtons>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
