import { api } from '@teamflow/client-api'
import { LogManager } from '@teamflow/lib'
import rootStore from '@teamflow/store'

export const fetchSlackConnected = async (scope: 'app' | 'user') => {
    let connected = false
    let notifyJoinTeamCount

    const { data, error } = await api.slack.checkAccess({ scope })
    if (data) {
        connected = data.access
        notifyJoinTeamCount = data.settings?.notifyJoinTeamCount
    } else if (error) {
        LogManager.global.error('fetchSlackConnected', error)
    }

    if (
        connected &&
        scope === 'app' &&
        typeof notifyJoinTeamCount === 'number'
    ) {
        rootStore.slack.updateSettings({
            notifyJoinTeamCount,
        })
    }

    rootStore.slack[scope === 'app' ? 'setAppConnected' : 'setUserConnected'](
        connected
    )
}
