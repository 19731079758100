import { observer } from 'mobx-react'
import {
    ChangeEventHandler,
    FormEventHandler,
    PropsWithChildren,
    ReactElement,
    useCallback,
    useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { track, useFeatureFlag } from '@teamflow/bootstrap'
import { api } from '@teamflow/client-api'
import {
    Box,
    Button,
    Card,
    Input,
    ItemWithMessage,
    Text,
} from '@teamflow/design'
import { LogManager } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import allTKeys from '@teamflow/translations'
import { Feature, SalesFloorDialer } from '@teamflow/types'
import {
    AircallLogo,
    DialpadLogo,
    JustCallLogo,
    OutreachLogo,
    PhoneBurnerLogo,
    RingCentralLogo,
    SalesLoftLogo,
    ZoomLogo,
} from '@teamflow/ui-icons'

import { SUPPORT_EMAIL } from '../../constants'
import { Salesfloor } from '../../helpers/analytics'
import * as integration from '../../helpers/salesfloorIntegrations'

import HuddleHeading from '../common/HuddleHeading'

import * as styles from './setupSalesfloorDialers.css'

const translationPage = 'salesfloor'
const tKeys = allTKeys.salesfloor

type SetupSalesfloorDialersProps = {
    onContinue: () => void
}

type InfoIntegrations = 'phoneburner' | 'aircall'

export default function SetupSalesfloorDailers(
    props: SetupSalesfloorDialersProps
) {
    const { t } = useTranslation(translationPage)

    const [showingInfoFor, setShowingInfoFor] =
        useState<InfoIntegrations | null>(null)

    const salesloftFeature = useFeatureFlag(Feature.SalesFloorSalesloft)
    const outreachFeature = useFeatureFlag(Feature.SalesFloorOutreach)
    const ringCentralFeature = useFeatureFlag(Feature.SalesFloorRingCentral)
    const justCallFeature = useFeatureFlag(Feature.SalesFloorJustCall)
    const zoomFeature = useFeatureFlag(Feature.SalesFloorZoom)
    const aircallFeature = useFeatureFlag(Feature.SalesFloorAircall)
    const phoneBurnerFeature = useFeatureFlag(Feature.SalesFloorPhoneBurner)
    const dialpadFeature = useFeatureFlag(Feature.SalesFloorDialpad)

    const onClose = useCallback(() => {
        props.onContinue()
    }, [props])

    const goBackFromShowingInfo = useCallback(() => {
        setShowingInfoFor(null)
    }, [setShowingInfoFor])

    const handleSalesloftAuth = useCallback(() => {
        integration.connectSalesLoft()
    }, [])

    const handleOutreachAuth = useCallback(() => {
        integration.connectOutreach()
    }, [])

    const handleRingCentralAuth = useCallback(() => {
        integration.connectRingCentral()
    }, [])

    const handleJustCallAuth = useCallback(() => {
        integration.connectJustCall()
    }, [])

    const handleZoomAuth = useCallback(() => {
        integration.connectZoom()
    }, [])

    const handleAircallAuth = useCallback(() => {
        setShowingInfoFor('aircall')
    }, [setShowingInfoFor])

    const handlePhoneBurnerAuth = useCallback(() => {
        setShowingInfoFor('phoneburner')
    }, [setShowingInfoFor])

    const handleDialpadAuth = useCallback(() => {
        integration.connectDialpad(onClose)
    }, [onClose])

    if (showingInfoFor === 'phoneburner') {
        return (
            <InfoPage
                dialer="PhoneBurner"
                goBack={goBackFromShowingInfo}
                onClose={onClose}
                logo={<PhoneBurnerLogo width={300} />}
            >
                <Text size="textSize14">
                    {t(
                        tKeys.phoneburner
                            .toUsePhoneBurnerWithTeamflowFollowTheseSteps
                    )}
                </Text>
                <ol className={styles.list}>
                    <li className={styles.listItem}>
                        {t(
                            tKeys.phoneburner
                                .navigateToThePhoneBurnerWebhooksDashboardAt
                        )}{' '}
                        <a
                            className={styles.link}
                            href="https://www.phoneburner.com/settings/developer/webhooks/index"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://www.phoneburner.com/settings/developer/webhooks/index
                        </a>
                    </li>
                    <li className={styles.listItem}>
                        {t(
                            tKeys.phoneburner
                                .copyTheFollowingURLIntoTheCallBeginWebhookInput
                        )}
                        :
                        <br />
                        <code className={styles.code}>
                            https://app.teamflowhq.com/api/webhooks/phoneburner/start
                        </code>
                    </li>
                    <li className={styles.listItem}>
                        {t(
                            tKeys.phoneburner
                                .copyTheFollowingURLIntoTheCallEndsWebhookInput
                        )}
                        :
                        <br />
                        <code className={styles.code}>
                            https://app.teamflowhq.com/api/webhooks/phoneburner/end
                        </code>
                    </li>
                    <li className={styles.listItem}>
                        {t(tKeys.phoneburner.clickSaveChanges)}
                    </li>
                    <li className={styles.listItem}>
                        {t(tKeys.integrationsPage.youreAllSet)}
                    </li>
                </ol>
                <Text size="textSize14">
                    <Trans
                        t={t}
                        i18nKey={
                            tKeys.integrationsPage
                                .ifYouHaveAnyIssuesSettingUpTheIntegrationPleaseContactSupportForAssistance
                        }
                        values={{ email: SUPPORT_EMAIL }}
                    >
                        <a
                            className={styles.link}
                            href={`mailto:${SUPPORT_EMAIL}`}
                        >
                            text
                        </a>
                    </Trans>
                </Text>
            </InfoPage>
        )
    }

    if (showingInfoFor === 'aircall') {
        return (
            <InfoPage
                dialer="Aircall"
                goBack={goBackFromShowingInfo}
                onClose={onClose}
                logo={<AircallLogo width={300} />}
            >
                <AirCallInfoPage />
            </InfoPage>
        )
    }

    return (
        <>
            <HuddleHeading>
                {t(tKeys.integrationsPage.connectYourTools)}
            </HuddleHeading>
            <div className={styles.grid}>
                {aircallFeature.enabled && (
                    <DialerCard
                        name="Aircall"
                        dialer={SalesFloorDialer.Aircall}
                        icon={<AircallLogo width="100%" height="100%" />}
                        onClick={handleAircallAuth}
                    />
                )}
                {salesloftFeature.enabled && (
                    <DialerCard
                        name="SalesLoft"
                        dialer={SalesFloorDialer.Salesloft}
                        icon={<SalesLoftLogo width="100%" height="100%" />}
                        onClick={handleSalesloftAuth}
                    />
                )}
                {outreachFeature.enabled && (
                    <DialerCard
                        name="Outreach"
                        dialer={SalesFloorDialer.Outreach}
                        icon={<OutreachLogo width="100%" height="100%" />}
                        onClick={handleOutreachAuth}
                    />
                )}
                {ringCentralFeature.enabled && (
                    <DialerCard
                        name="Ring Central"
                        dialer={SalesFloorDialer.RingCentral}
                        icon={<RingCentralLogo width="100%" height="100%" />}
                        onClick={handleRingCentralAuth}
                    />
                )}
                {justCallFeature.enabled && (
                    <DialerCard
                        name="JustCall"
                        dialer={SalesFloorDialer.JustCall}
                        icon={<JustCallLogo width="100%" height="100%" />}
                        onClick={handleJustCallAuth}
                    />
                )}
                {zoomFeature.enabled && (
                    <DialerCard
                        name="Zoom"
                        dialer={SalesFloorDialer.Zoom}
                        icon={<ZoomLogo width="100%" height="100%" />}
                        onClick={handleZoomAuth}
                    />
                )}
                {phoneBurnerFeature.enabled && (
                    <DialerCard
                        name="PhoneBurner"
                        dialer={SalesFloorDialer.PhoneBurner}
                        icon={<PhoneBurnerLogo width="100%" height="100%" />}
                        onClick={handlePhoneBurnerAuth}
                    />
                )}
                {dialpadFeature.enabled && (
                    <DialerCard
                        name="Dialpad"
                        dialer={SalesFloorDialer.Dialpad}
                        icon={<DialpadLogo width="100%" height="100%" />}
                        onClick={handleDialpadAuth}
                    />
                )}
            </div>
            <Button onClick={onClose} marginTop="space20" level="primary">
                {t(tKeys.integrationsPage.continue)}
            </Button>
        </>
    )
}

type DialerCardProps = {
    name: string
    dialer: SalesFloorDialer
    icon: ReactElement
    onClick: () => void
}

function DialerCard(props: DialerCardProps) {
    const handleOnClick = useCallback(() => {
        track(Salesfloor.ClickSetupDialer, {
            dialer: props.dialer,
        })
        props.onClick()
    }, [props])

    return (
        <Card
            as="button"
            className={styles.card}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            shadow="lg"
            border="neutral20"
            onClick={handleOnClick}
            cursor="pointer"
            height="size160"
        >
            <Box width="size56" height="size56" marginBottom="space24">
                {props.icon}
            </Box>
            <Text className={styles.cardLabel} size="textSize16" weight="600">
                {props.name}
            </Text>
        </Card>
    )
}

type InfoPageProps = PropsWithChildren<{
    dialer: string
    logo: ReactElement
    goBack: () => void
    onClose: () => void
}>

function InfoPage(props: InfoPageProps) {
    const { t } = useTranslation(translationPage)

    return (
        <>
            {props.logo}
            <HuddleHeading marginTop="space20">
                {t(tKeys.integrationsPage.setupDialer, {
                    dialer: props.dialer,
                })}
            </HuddleHeading>
            {props.children}
            <Box>
                <Button
                    marginRight="space8"
                    marginTop="space20"
                    level="neutral"
                    kind="text"
                    onClick={props.goBack}
                >
                    {t(tKeys.integrationsPage.goBack)}
                </Button>
                <Button
                    onClick={props.onClose}
                    marginTop="space20"
                    level="primary"
                >
                    {t(tKeys.integrationsPage.continue)}
                </Button>
            </Box>
        </>
    )
}

const AirCallInfoPage = observer(() => {
    const { t } = useTranslation(translationPage)

    const [isSavingToken, setIsSavingToken] = useState(false)
    const [token, setToken] = useState('')
    const [saveTokenError, setSaveTokenError] = useState<string>()
    const [tokenHasSavedSuccessfully, setTokenHasSavedSuccessfully] =
        useState(false)

    const orgId = rootStore.users.localUser?.currentOrgId

    const handleSaveToken = useCallback<FormEventHandler<HTMLFormElement>>(
        async (e) => {
            e.preventDefault()

            const orgId = rootStore.users.localUser?.currentOrgId

            // we don't show the form if there's no orgId so this is fine
            if (!orgId) return

            setIsSavingToken(true)
            setSaveTokenError(undefined)

            try {
                const { error } =
                    await api.organization.integration.aircall.addVerificationToken(
                        {
                            orgId,
                            token,
                        }
                    )

                if (error) {
                    LogManager.ui.error({
                        action: 'Integration@Aircall',
                        message: 'failed to save verification token',
                        error,
                    })
                    setSaveTokenError(error.message || 'failed to save token')
                }

                setTokenHasSavedSuccessfully(true)
            } catch (error: any) {
                LogManager.ui.error({
                    action: 'Integration@Aircall',
                    message: 'failed to save verification token',
                    error,
                })
                setSaveTokenError(error.message)
            } finally {
                setIsSavingToken(false)
            }
        },
        [token]
    )

    const handleTokenInputChange = useCallback<
        ChangeEventHandler<HTMLInputElement>
    >(
        (e) => {
            setToken(e.target.value)
        },
        [setToken]
    )

    return (
        <>
            <Text size="textSize14">
                {t(tKeys.aircall.toUserAirCallWithTeamflowFollowTheseSteps)}
            </Text>
            <ol className={styles.list}>
                <li className={styles.listItem}>
                    {t(tKeys.aircall.haveAnAircallAdminUserSignInToAircallAt)}{' '}
                    <a
                        className={styles.link}
                        href="https://dashboard.aircall.io/ "
                        target="_blank"
                        rel="noreferrer"
                    >
                        https://dashboard.aircall.io/
                    </a>
                </li>
                <li className={styles.listItem}>
                    {t(tKeys.aircall.clickOnIntegrationsAndAPI)}
                </li>
                <li className={styles.listItem}>
                    {t(tKeys.aircall.clickOnDiscoverIntegrations)}
                </li>
                <li className={styles.listItem}>
                    {t(tKeys.aircall.scrollDownToWebhookInstallIntegration)}
                </li>
                <li className={styles.listItem}>
                    {t(tKeys.aircall.registerTheWebhookName)}:
                    <br />
                    <code className={styles.code}>
                        https://app.teamflowhq.com/api/webhooks/aircall
                    </code>
                </li>
                <li className={styles.listItem}>
                    {t(tKeys.aircall.makeSureTheCallEventsAreToggled)}
                </li>
                <li className={styles.listItem}>
                    {t(tKeys.aircall.saveTheWebhookAndSaveToken)}{' '}
                    {!orgId ? (
                        <Trans
                            t={t}
                            i18nKey={
                                tKeys.aircall.copyThatTokenAndSendItToTeamflow
                            }
                            values={{ email: 'kieth@teamflowhq.com' }}
                        >
                            <a
                                className={styles.link}
                                href="mailto:keith@teamflowhq.com"
                            >
                                text
                            </a>
                        </Trans>
                    ) : (
                        <>
                            {t(
                                tKeys.aircall
                                    .copyTheTokenAndSaveItInTheFollowingInput
                            )}
                            <form onSubmit={handleSaveToken}>
                                <Box
                                    marginBottom="space12"
                                    marginTop="space12"
                                    display="flex"
                                >
                                    <Box flexGrow={1} marginRight="space8">
                                        <ItemWithMessage
                                            show={
                                                !!saveTokenError ||
                                                tokenHasSavedSuccessfully
                                            }
                                            level={
                                                saveTokenError
                                                    ? 'danger'
                                                    : 'success'
                                            }
                                            message={
                                                saveTokenError
                                                    ? t(
                                                          tKeys.aircall
                                                              .failedToSaveToken
                                                      )
                                                    : t(
                                                          tKeys.aircall
                                                              .tokenSuccessfullySaved
                                                      )
                                            }
                                        >
                                            <Input
                                                value={token}
                                                onChange={
                                                    handleTokenInputChange
                                                }
                                            />
                                        </ItemWithMessage>
                                    </Box>
                                    <Button
                                        __cssOverrides={{
                                            width: 116,
                                            height: 40,
                                        }}
                                        isLoading={isSavingToken}
                                        type="submit"
                                        disabled={
                                            !token || tokenHasSavedSuccessfully
                                        }
                                    >
                                        {t(tKeys.aircall.submitToken)}
                                    </Button>
                                </Box>
                            </form>
                        </>
                    )}
                </li>

                <li className={styles.listItem}>
                    {t(tKeys.integrationsPage.youreAllSet)}
                </li>
            </ol>
            <Text size="textSize14">
                <Trans
                    t={t}
                    i18nKey={
                        tKeys.integrationsPage
                            .ifYouHaveAnyIssuesSettingUpTheIntegrationPleaseContactSupportForAssistance
                    }
                    values={{ email: SUPPORT_EMAIL }}
                >
                    <a className={styles.link} href={`mailto:${SUPPORT_EMAIL}`}>
                        text
                    </a>
                </Trans>
            </Text>
        </>
    )
})
