import differenceBy from 'lodash/differenceBy'
import uniq from 'lodash/uniq'
import { observer } from 'mobx-react'
import { useState, useEffect } from 'react'

import { api } from '@teamflow/client-api'
import {
    Box,
    Button,
    Card,
    Divider,
    Label,
    Link,
    Stack,
    Text,
} from '@teamflow/design'
import { getPublicUrl } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import { Experiment } from '@teamflow/types'

import { analytics } from '../../helpers'
import { useOptimizelyTest } from '../../hooks/useABTest'

import { Avatar } from '../common'
import HuddleHeading from '../common/HuddleHeading'

import MultiEmailInput from './MultiEmailInput'
import { pluralize } from './shared/pluralize'

type Props = {
    onContinue: () => void
}

export default observer(function InviteCreditsScreen({ onContinue }: Props) {
    const [invitedEmails, setInvitedEmails] = useState<string[]>([])
    const [loading, setLoading] = useState(false)
    const user = rootStore.users.localUser
    const { active: inviteCreditActive } = useOptimizelyTest(
        Experiment.InviteCredit,
        user?._id,
        {
            attributes: { role: user?.role ?? 0 },
        }
    )

    useEffect(() => {
        if (!inviteCreditActive) {
            return
        }
        analytics.track(analytics.Signup.InviteForCreditStart)
    }, [inviteCreditActive])

    const onSubmitEmails = async (emails: string[]) => {
        const newEmails = differenceBy(emails, invitedEmails, (email) =>
            email.toLocaleLowerCase()
        )

        setInvitedEmails(uniq([...invitedEmails, ...newEmails]))
    }

    const onNext = async () => {
        setLoading(true)

        // send the invites
        await api.email.inviteToOrg({
            emails: invitedEmails.map((invited) => ({ email: invited })),
            tag: Experiment.InviteCredit,
        })

        setLoading(false)

        // track event for test
        analytics.track(analytics.Signup.InviteForCredit, {
            invitedEmails: invitedEmails.length,
        })

        onContinue()
    }

    const remainingInvitesRequired = Math.max(0, 5 - invitedEmails.length)

    return (
        <Box __cssOverrides={{ maxWidth: 450 }}>
            <HuddleHeading>
                Earn account credits for inviting your team
            </HuddleHeading>
            <MultiEmailInput
                label="Invite via email"
                onSubmit={onSubmitEmails}
                loading={loading}
            />
            <Card
                background="neutral20"
                width="fill"
                border="neutral40"
                radius="borderRadius6"
                padding="space24"
                marginTop="space32"
                display="flex"
                alignItems="center"
                gap="space16"
            >
                <img
                    src={getPublicUrl('/images/gift-round.png')}
                    width={48}
                    height={48}
                />
                <Box>
                    <Text size="textSize14">
                        Earn <b>$100 credit to your account</b> for the first
                        five team members you invite. You’ll receive this credit
                        when your teammates accept their invite.
                    </Text>
                </Box>
            </Card>

            {invitedEmails.length > 0 && (
                <Box display="flex" flexDirection="column" marginTop="space32">
                    <Label>Team members to invite</Label>
                    <Box
                        marginTop="space20"
                        paddingRight="space8"
                        overflowY="auto"
                        display="flex"
                        flexDirection="column"
                        gap="space12"
                        __cssOverrides={{ maxHeight: 300 }}
                    >
                        {invitedEmails.map((email) => {
                            return (
                                <Box
                                    key={email}
                                    display="flex"
                                    alignItems="center"
                                    gap="space10"
                                >
                                    <Avatar size="md" name={email} capitalize />
                                    <Box
                                        flexGrow={1}
                                        flexShrink={1}
                                        overflow="hidden"
                                        paddingY="space4"
                                    >
                                        <Stack space="space8">
                                            <Text
                                                size="textSize14"
                                                weight="500"
                                            >
                                                Invite will be sent to
                                            </Text>
                                            <Text
                                                size="textSize12"
                                                color="neutral70"
                                                truncate
                                            >
                                                {email}
                                            </Text>
                                        </Stack>
                                    </Box>
                                    <Text
                                        size="textSize12"
                                        weight="500"
                                        color="neutral70"
                                        whiteSpace="nowrap"
                                    >
                                        $20 credit
                                    </Text>
                                </Box>
                            )
                        })}
                    </Box>
                    <Divider color="neutral40" marginY="space24" />
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="between"
                    >
                        <Text size="textSize14" weight="600">
                            Total credits earned
                        </Text>
                        {remainingInvitesRequired > 0 ? (
                            <Text size="textSize14" weight="600" color="danger">
                                Add at least {remainingInvitesRequired} more{' '}
                                {pluralize('member', remainingInvitesRequired)}
                            </Text>
                        ) : (
                            <Text size="textSize14" weight="600">
                                ${Math.min(5, invitedEmails.length) * 20}
                            </Text>
                        )}
                    </Box>
                </Box>
            )}

            <Button
                size="lg"
                isFullWidth
                disabled={invitedEmails.length < 5}
                marginTop="space32"
                onClick={onNext}
            >
                Send Invites
            </Button>
            <Box marginTop="space16">
                <Text align="center" width="fill">
                    <Link level="neutral" onClick={onContinue}>
                        Skip Sending Invites
                    </Link>
                </Text>
            </Box>
        </Box>
    )
})
