import { useId } from '@reach/auto-id'
import React, { useState } from 'react'

import {
    Box,
    BoxProps,
    Button,
    Input,
    Label,
    Stack,
    Tag,
} from '@teamflow/design'

import { isValidEmail } from '../../helpers/email'

interface Props {
    label?: string
    onSubmit?: (emails: string[]) => void
    loading?: boolean
    width?: BoxProps['width']
}

export default function MultiEmailInput({
    label,
    onSubmit,
    loading = false,
    width,
}: Props) {
    const [emails, setEmails] = useState<string[]>([])
    const [pendingEmail, setPendingEmail] = useState('')

    function addPendingEmail() {
        setEmails([...emails, pendingEmail])
        setPendingEmail('')
    }

    function removeEmail(index: number) {
        const newEmails = [...emails]
        newEmails.splice(index, 1)
        setEmails(newEmails)
    }

    function handleKeyDown(e: React.KeyboardEvent) {
        switch (e.key) {
            case 'Enter':
            case 'Tab':
            case ' ':
                e.preventDefault()
                if (isValidEmail(pendingEmail)) {
                    addPendingEmail()
                }
                break
        }
    }

    function handleSubmit() {
        onSubmit?.(emails)
        setEmails([])
        setPendingEmail('')
    }

    const autoId = useId()

    let tagElements: React.ReactNode = null

    if (emails.length > 0) {
        tagElements = (
            <Box display="flex" flexWrap="wrap" gap="space4" width="fill">
                {emails.map((email, index) => (
                    <Tag key={index} onRemove={() => removeEmail(index)}>
                        {email}
                    </Tag>
                ))}
            </Box>
        )
    }

    return (
        <Stack space="space12" width={width}>
            {label && <Label htmlFor={autoId}>{label}</Label>}
            <Box
                display="flex"
                alignItems="start"
                gap="space8"
                width="fill"
                overflow="hidden"
            >
                <Box flexGrow={1} flexShrink={1} overflow="hidden">
                    <Input
                        id={autoId}
                        value={pendingEmail}
                        onChange={(e) => setPendingEmail(e.target.value)}
                        type="email"
                        aria-describedby="email-helper-text"
                        placeholder="bob@company.com"
                        onKeyDown={handleKeyDown}
                    >
                        {tagElements}
                    </Input>
                </Box>
                <Button
                    size="md"
                    disabled={emails.length === 0}
                    isLoading={loading}
                    onClick={handleSubmit}
                >
                    Invite
                </Button>
            </Box>
        </Stack>
    )
}
