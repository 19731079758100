import { observer } from 'mobx-react'
import { ReactNode, useCallback, useEffect, useState } from 'react'

import { Button, ButtonProps, colorPalette } from '@teamflow/design'
import rootStore from '@teamflow/store'
import { SlackIcon } from '@teamflow/ui-icons'

import { SLACK_WORKSPACE_SCOPES_INVITE } from '../../constants'
import { track } from '../../helpers/analytics'
import openPopup from '../../helpers/openPopup'
import { setHref } from '../../helpers/reload'
import useSlackConnected from '../../hooks/useSlackConnected'
import { ElectronService } from '../../verse/services/ElectronService'

const NEXT_PUBLIC_SLACK_CLIENT_ID = process.env.NEXT_PUBLIC_SLACK_CLIENT_ID

export default observer(function SlackInviteButton({
    onConnected,
    children,
    buttonLocation,
    ...props
}: {
    onConnected: () => void
    children: ReactNode
    buttonLocation: string
} & Omit<ButtonProps, 'ref'>) {
    const [requested, setRequested] = useState(false)
    const { connected, loading } = useSlackConnected(
        rootStore,
        'app',
        requested
    )
    const id = rootStore.users.localUserId

    const onConnect = useCallback(() => {
        if (connected) {
            onConnected()
            return
        }
        const redirectURI = encodeURIComponent(
            `${process.env.NEXT_PUBLIC_APP_URL}/api/slack/oauth_redirect`
        )
        const url = `https://slack.com/oauth/v2/authorize?scope=${SLACK_WORKSPACE_SCOPES_INVITE}&client_id=${NEXT_PUBLIC_SLACK_CLIENT_ID}&redirect_uri=${redirectURI}&state=${id}`

        const popup = openPopup(
            url,
            800,
            Math.min(window.screen.height - 40, 800)
        )
        if (!popup && !ElectronService.available) {
            setHref(url)
            return
        }
        setRequested(true)

        track('slack_team_invite_clicked', {
            buttonLocation,
        })
    }, [buttonLocation, connected, id, onConnected])

    useEffect(() => {
        if (requested && connected) {
            setRequested(false)
            onConnected()
            track('slack_team_invite_connected', {
                buttonLocation,
            })
        }
    }, [buttonLocation, connected, onConnected, requested])

    return (
        <Button
            size="md"
            kind="text"
            level="neutral"
            isFullWidth
            leadingIcon={<SlackIcon size={24} mr={16} />}
            isLoading={loading}
            loadingText={children}
            onClick={onConnect}
            style={{
                border: `2px solid ${colorPalette.neutral700}`,
                color: colorPalette.neutral800,
            }}
            {...props}
        >
            {children}
        </Button>
    )
})
