import { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Platform } from '@teamflow/bootstrap'
import {
    Box,
    Button,
    Center,
    Columns,
    Link,
    Stack,
    Text,
} from '@teamflow/design'
import { isSafari } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import allTKeys from '@teamflow/translations'

import { openDesktopApp } from '../../helpers'
import { track, eventWithFlow, Signup } from '../../helpers/analytics'
import getAppDownloadUrl from '../../helpers/appDownload'
import { ElectronService } from '../../verse/services/ElectronService'

import HuddleHeading from '../common/HuddleHeading'
import HuddleLoader from '../common/HuddleLoader'

const translationPage = 'signup'
const tKeys = allTKeys.signup.download
const redirectTKeys = allTKeys.redirect

function ListItem({ num, children }: { num: string; children: string }) {
    return (
        <Columns space="space12" alignY="center">
            <Center
                background="primaryLighter"
                width="size24"
                height="size24"
                borderRadius="borderRadius12"
                flexShrink={0}
            >
                <Text
                    selectable={false}
                    color="neutral10"
                    size="textSize14"
                    weight="600"
                >
                    {num}
                </Text>
            </Center>

            <Text selectable={false}>{children}</Text>
        </Columns>
    )
}

interface DownloadProps {
    onContinue: () => void
    source?: string
}

export default function Download({
    onContinue,
    source,
}: DownloadProps): JSX.Element {
    const { t } = useTranslation(translationPage)

    // We don't come to this step if getAppDownloadUrl() isn't available
    const downloadUrl = getAppDownloadUrl() as string
    const [downloaded, setDownloaded] = useState(false)
    const [didDesktopAppOpen, setDidDesktopAppOpen] = useState(false)
    const isSalesFloor = rootStore.organization.isSalesFloor

    const tryToOpenDesktopApp = useCallback(
        (allowSafari = false) => {
            if (!isSafari() || allowSafari) {
                void openDesktopApp().then((didOpen: boolean) => {
                    if (didOpen) {
                        setDidDesktopAppOpen(didOpen)
                        track(
                            eventWithFlow(
                                Signup.DownloadScreenOpenedApp,
                                source
                            )
                        )
                    }
                })
            }
        },
        [source]
    )

    const tryToOpenAppAllowingSafari = useCallback(() => {
        tryToOpenDesktopApp(true)
    }, [tryToOpenDesktopApp])

    useEffect(() => {
        if (
            ElectronService.available ||
            !(Platform.isWindows() || Platform.isMacOS()) ||
            Platform.isTfBot() ||
            isSalesFloor
        ) {
            if (isSalesFloor) {
                track(eventWithFlow(Signup.DownloadScreenSkipped, source))
            }
            onContinue()
            return
        }

        track(eventWithFlow(Signup.DownloadScreenShown, source))
        tryToOpenDesktopApp()
    }, [onContinue, source, tryToOpenDesktopApp, isSalesFloor])

    const tryAndDownload = useCallback(() => {
        window.open(downloadUrl)
        setDownloaded(true)
        track(eventWithFlow(Signup.DownloadButtonClicked, source))
    }, [downloadUrl, source])

    async function handleContinue() {
        track(eventWithFlow(Signup.NoThanksUseBrowser, source))
        onContinue()
    }

    if (downloaded) {
        return (
            <>
                <HuddleHeading>{t(tKeys.doneDownloading)}</HuddleHeading>
                <Box marginBottom="space32" __cssOverrides={{ maxWidth: 315 }}>
                    <Stack space="space16">
                        <ListItem num="1">
                            {t(
                                tKeys.openTheFileByDoubleClickingItInYourDownloadsFolder
                            )}
                        </ListItem>
                        <ListItem num="2">
                            {t(
                                tKeys.followTheInstructionsToInstallTeamflowToYourComputer
                            )}
                        </ListItem>
                        <ListItem num="3">
                            {t(
                                tKeys.afterTheAppIsInstalledLaunchTeamflowAndSignIn
                            )}
                        </ListItem>
                    </Stack>
                </Box>

                <Link
                    level="neutral"
                    onClick={handleContinue}
                    data-testid="signup-no-thanks-btn"
                >
                    {t(tKeys.noThanksIllGoToMyOfficeInTheBrowser)}
                </Link>
            </>
        )
    } else if (didDesktopAppOpen) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="fill"
            >
                <HuddleHeading>{t(tKeys.openTheDesktopApp)}</HuddleHeading>
                <Box marginBottom="space12">
                    <Text selectable={false} align="center">
                        {t(tKeys.clickOpenTeamflowToLaunchTheApp)}
                    </Text>
                </Box>
                <Text selectable={false} align="center">
                    {t(tKeys.canAlsoOpenInTheBrowser)}{' '}
                    <Link
                        level="neutral"
                        onClick={handleContinue}
                        data-testid="signup-no-thanks-btn"
                    >
                        {t(tKeys.inTheBrowser)}
                    </Link>
                </Text>
                <Button
                    size="lg"
                    marginTop="space24"
                    marginBottom="space24"
                    onClick={tryToOpenDesktopApp}
                >
                    {t(tKeys.openTeamflowDesktop)}
                </Button>
                <Text selectable={false} align="center">
                    {t(tKeys.dontHaveTeamflowInstalled)}{' '}
                    <Link level="neutral" onClick={tryAndDownload}>
                        {t(tKeys.downloadNow)}
                    </Link>
                </Text>
            </Box>
        )
    } else if (isSalesFloor) {
        return (
            <>
                <HuddleLoader>
                    {t(redirectTKeys.holdTightLaunchingTeamflow)}
                </HuddleLoader>
            </>
        )
    }
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="fill"
        >
            <HuddleHeading>{t(tKeys.downloadTheApp)}</HuddleHeading>

            <Text selectable={false} align="center">
                {t(
                    tKeys.hangOutWithYourTeamOnOurDesktopAppAndEnjoyBetterPerformanceOrJoinRightFromTheBrowser
                )}
            </Text>

            <Button
                size="lg"
                marginTop="space24"
                marginBottom="space24"
                onClick={tryAndDownload}
            >
                {t(tKeys.download)}
            </Button>

            <Text selectable={false} align="center">
                Already have the app installed?{' '}
                <Link level="neutral" onClick={tryToOpenAppAllowingSafari}>
                    Open Desktop App
                </Link>
            </Text>
            <Box marginTop="space12">
                <Link level="neutral" onClick={handleContinue}>
                    <Text color="inherit">
                        {t(tKeys.noThanksIllGoToMyOfficeInTheBrowser)}
                    </Text>
                </Link>
            </Box>
        </Box>
    )
}
