import { useCallback, useEffect, useRef, useState } from 'react'

import type { RootStore } from '@teamflow/store'

import { fetchSlackConnected } from '../requests/fetchSlackConnected'

export default function useSlackConnected(
    rootStore: RootStore,
    scope: 'app' | 'user',
    refresh = false
) {
    const mounted = useRef(false)
    const [loading, setLoading] = useState<boolean>(true)
    const connected =
        scope === 'app'
            ? rootStore.slack.appConnected
            : rootStore.slack.userConnected
    const notifyJoinTeamCount = rootStore.slack.notifyJoinTeamCount

    const checkConnected = useCallback(
        async function () {
            setLoading(true)
            await fetchSlackConnected(scope)
            if (mounted.current) {
                setLoading(false)
            }
        },
        [scope]
    )

    useEffect(() => {
        mounted.current = true

        void checkConnected()

        let pollingId = 0

        if (refresh) {
            pollingId = window.setInterval(async () => {
                await fetchSlackConnected(scope)
            }, 2000)
        }

        return () => {
            mounted.current = false
            window.clearInterval(pollingId)
        }
    }, [checkConnected, refresh, scope])

    return { connected, loading, checkConnected, notifyJoinTeamCount }
}
