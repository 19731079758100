import isEqual from 'lodash/isEqual'
import { useEffect, useState } from 'react'

import { IABTest, IABTestService } from '../types/abtests'
import { optimizelyTestService } from '../verse/services/abtests'

type Options = {
    attributes?: Record<string, any>
    activeVariation?: (variation: string | null) => boolean
}

const defaultActiveVariation = (variation: string | null) => variation === 'on'

function createHook<Flag>(testService: IABTestService<Flag>) {
    return function <Events extends string, Variables extends string>(
        flag: Flag,
        userId?: string,
        options: Options = {}
    ) {
        const { attributes = {}, activeVariation = defaultActiveVariation } =
            options

        const [initialized, setInitialized] = useState(testService.initialized)
        const [test, setTest] = useState<IABTest<Variables, Events>>(
            testService.get<Variables, Events>(flag, userId ?? '')
        )
        const [enabled, setEnabled] = useState(false)
        const [variation, setVariation] = useState<string | null>(null)
        const [ready, setReady] = useState(false)
        const [cachedAttributes, setCachedAttributes] = useState({})

        useEffect(() => {
            if (testService.initialized) {
                return
            }

            let unmounted = false

            void testService.initialize().then(() => {
                if (!unmounted) setInitialized(true)
            })

            return () => {
                unmounted = true
            }
        }, [])

        useEffect(() => {
            if (!initialized || !userId) {
                return
            }

            if (isEqual(attributes, cachedAttributes)) {
                return
            }

            const test = testService.get<Variables, Events>(flag, userId)
            setTest(test)
            setEnabled(test.getEnabled(attributes))
            setVariation(test.getVariation(attributes))
            setReady(true)
            setCachedAttributes(attributes)
        }, [initialized, flag, userId, attributes, cachedAttributes])

        return {
            test,
            ready,
            enabled,
            variation,
            active: enabled && activeVariation(variation),
        }
    }
}

export const useOptimizelyTest = createHook(optimizelyTestService)
