import { analytics } from '../../helpers'

import CreditsModal from './shared/CreditsModal'

type Props = {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
}

export default function InviteCreditsModal({
    isOpen,
    onClose,
    onConfirm,
}: Props) {
    return (
        <CreditsModal
            isOpen={isOpen}
            onClose={() => {
                onClose()
                analytics.track(analytics.Signup.InviteCreditsModalSkip)
            }}
            onConfirm={() => {
                onConfirm()
                analytics.track(analytics.Signup.InviteCreditsModalConfirm)
            }}
        >
            We’re confident you’ll have more collaborative and focused meetings
            in Teamflow.{' '}
            <b>
                Earn $100 credit to your account for the first five team members
                you invite.
            </b>{' '}
            You’ll receive this credit when your teammate accepts their invite.
        </CreditsModal>
    )
}
